
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { BASE_URL_FRONTEND } from "../../config/Url-config";
import { SlUserFollowing, SlUserUnfollow } from "react-icons/sl";
import ChangePassword from "./UserChangePassword";
import SuccessPopUp from "../Utility/SuccessPopUp";
import SpinnerBox from "../Utility/SpinnerBox";
import ErrorPopUp from "../Utility/ErrorPopUp";
import DeactivateAccount from "./UserDeactivation";
import * as apiUtility from "../../config/APIUtils";
import { Divider, Icon, Tooltip, Checkbox, FormControlLabel, FormGroup, } from "@mui/material";
import { RxInfoCircled } from "react-icons/rx";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import UserConsentDialog from "../Utility/UserConsentDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Card, CardContent, IconButton, Typography, CardOverflow, Alert } from "@mui/joy";
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { AlbertaCities, BritishColumbiaCities, ManitobaCities, NewBrunswickCities, NewFoundLandCities, NorthWestCities, NovaScotiaCities, NunavatCities, OntarioCities, PEICities, QuebecCities, SaskatchewanCities, YukonCities } from "../Utility/Constants";

const intialState = {
  firstName: "",
  middleName: "",
  lastName: "",
  userName: "",
  cnum: "",
  password: "",
  confirmPassword: "",
  dob: "",
  gender: "",
  email: "",
  number: "",
  addressLine1: "",
  addressLine2: "",
  country: "",
  city: "",
  state: "",
  postalCode: "",
  verified: "",
  active: "",
  // careerGoal: '',
  // comCode: '',
  enrollCheck: false,
  consent: false,
};

var errors = {
  firstName: false,
  userName: false,
  email: false,
  dob: false,
  gender: false,
  number: false,
  cnum: false,
  country: false,
  city: false,
  state: false,
  postal: false,
  address: false,
};

var errorText = {
  firstName: "",
  userName: "",
  email: "",
  dob: "",
  gender: "",
  number: "",
  cnum: "",
  country: "",
  city: "",
  state: "",
  postal: "",
  address: "",
};

const data = {
  countries: [
    {
      name: "USA",
      states: [
        { name: "Massachusetts", cities: ["Downers Grove"] },
        { name: "New York", cities: ["New York City"] },
      ],
    },
    {
      name: "Canada",
      states: [
        {
          name: "Ontario",
          cities: OntarioCities
        },
        {
          name: "British Columbia",
          cities: BritishColumbiaCities
        },
        {
          name: "Manitoba",
          cities: ManitobaCities
        },
        {
          name: "New Brunswick",
          cities: NewBrunswickCities
        },
        {
          name: "Newfoundland and Labrador",
          cities: NewFoundLandCities
        },
        {
          name: "Northwest Territories",
          cities: NorthWestCities
        },
        {
          name: "Nova Scotia",
          cities: NovaScotiaCities
        },
        {
          name: "Alberta",
          cities: AlbertaCities
        },
        {
          name: "Nunavut",
          cities: NunavatCities
        },
        {
          name: "Prince Edward Island",
          cities: PEICities
        },
        {
          name: "Quebec",
          cities: QuebecCities
        },
        {
          name: "Saskatchewan",
          cities: SaskatchewanCities
        },
        { name: "Yukon Territory", cities: YukonCities },
      ],
    }
  ],
};

function UserProfile(props) {
  var paramId = useParams().id;
  const BASE_URL_USER = "user/getUser/";
  const BASE_URL_USER_UPDATE = "user/update/";
  //const BASE_URL_USER_FRONT_END = BASE_URL_FRONTEND + "user/";
  const [newUser, setNewUser] = useState(intialState);
  const [selectedCountry, setSelectedCountry] = React.useState();
  const [selectedState, setSelectedState] = React.useState();
  const [selectedCity, setSelectedCity] = React.useState();
  const [showDangerAlert, setshowDangerAlert] = useState(false);
  const [showSuccessAlert, setshowSuccessAlert] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [validationError, setValidationError] = useState(errors);
  const [showForm, setShowForm] = useState(false);
  const [studentData, setStudentData] = useState({});
  const [trainingProviders, setTrainingProviders] = useState(
    sessionStorage.getItem("training_providers") != undefined
      ? JSON.parse(sessionStorage.getItem("training_providers"))
      : []
  );
  const [newUserTrainingData, setNewUserTrainingData] = useState([]);
  const [showCnumError, setShowCnumError] = useState("");
  const [showValidateCnumError, setValidateCnumError] = useState(false);
  const [showIncompleteFieldsError, setShowIncompleteFieldsError] =
    useState(false);
  const [trainingErrorText, setTrainingErrorText] = useState({});
  const [myMap, setMyMap] = useState(new Map());

  const [showValidRegex, setShowValidRegex] = useState(false);

  const [errorDesc, setErrorDesc] = useState(errorText);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMsg, setsuccessMsg] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [arrayConsent, setArrayConsent] = useState("");
  const [consentArray, setConsentArray] = useState([]);

  const [showDeactivation, setShowDeactivation] = useState(false);
  const [verifiedStatus, setVerifiedStatus] = useState(true);
  const [activeStatus, setActiveStatus] = useState(true);
  const [consentDialog, setConsentDialog] = useState(false);


  const availableState = data.countries.find((c) => c.name === selectedCountry);
  const availableCities = availableState?.states?.find(
    (s) => s.name === selectedState
  );
  var paramId = useParams().id;
  var propsId = props.userId;
  var params = propsId;
  if (!propsId) {
    params = paramId;
  }
  useEffect(() => {
    if (
      sessionStorage.getItem("userType") != "admin" &&
      sessionStorage.getItem("userId") != paramId
    ) {
      return (window.location.href = BASE_URL_FRONTEND);
    }
    if (sessionStorage.getItem("tempUserTrainingData")) {
      sessionStorage.removeItem("tempUserTrainingData");
    }
    var apiParams = apiUtility.apiInputRequest;
    apiParams.endPoint = BASE_URL_USER + params;
    apiUtility
      .fetchAPIDataGET(apiParams)
      .then((res) => {
        var response = res.data.data;
        if (response !== undefined) {
          setSelectedCountry(response.country);
          setSelectedState(response.state);
          setNewUser(response);
          setNewUserTrainingData(response.student_numbers);

          if (!response.student_numbers || response.student_numbers.length < 1) {
            setShowForm(true);
          }
          else{
            let updatedMap = new Map(myMap);
            for (let i = 0; i < response.student_numbers.length; i++) {
              updatedMap.set(parseInt(response.student_numbers[i].tp_id), false);
            }
            setMyMap(updatedMap);  // Update the state with the new map
            
          }
          setVerifiedStatus(response.verified);
          setActiveStatus(
            response.active != undefined ? response.active : true
          );

          // if (response.cnum !== "" && response.cnum.length >= 0) {
          //   let cNumObj = { cnumFlag: false, cnumDesc: "" };
          //   validateLambtonId(response.cnum, cNumObj);
          //   errors["cnum"] = cNumObj.cnumFlag;
          //   validationError["cnum"] = cNumObj.cnumFlag;
          //   errorText["cnum"] = cNumObj.cnumDesc;
          //   errorDesc["cnumDesc"] = cNumObj.cnumDesc;
          //   setLambtonIdFromDb(response.cnum);
          // }
        } else {
          setErrorMsg("Internal server error. Please try again later.");
          setShowErrorMsg(true);
          setNewUser(intialState);
        }
      })
      .catch((err) => {
        setErrorMsg("Internal server error. Please try again later.");
        setShowErrorMsg(true);
        console.error("Request failed:", err);
      });
  }, [params]);

  function mapToObject(map) {
    let obj = {};
    map.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }

  const handleOnSubmit = async (e) => {
    //var errors = {};
    setShowSpinner(true);
    //e.preventDefault();
    try {
      if (validateMandatoryOnSubmit(newUser)) {
        //Form error exist
        return;
      }
      else {

        newUser.enrollCheck = newUser.enrollCheck === undefined ? false : newUser.enrollCheck;
        newUser.consent = newUser.consent === undefined ? false : newUser.consent;
        newUser.firstName = newUser.firstName.trim(); //.replace(/[^a-zA-Z]/g, "").toLowerCase();
        newUser.lastName = newUser.lastName.trim();
        newUser.middleName = newUser.middleName.trim(); //.replace(/[^a-zA-Z]/g, "").toLowerCase();
        newUser.userName = newUser.userName.trim(); //.replace(/[^a-zA-Z]/g, "").toLowerCase();
        newUser.student_numbers = newUserTrainingData;

        if (newUser.firstName === "" || newUser.firstName.length <= 0) {
          newUser.firstName = "-";
        }
        if (newUser.lastName === "" || newUser.lastName.length <= 0) {
          newUser.lastName = "-";
        }
        if (newUser.middleName === "" || newUser.middleName.length <= 0) {
          newUser.middleName = "-";
        }
      }
      var newMap = {
        userObj: newUser,
        update_student_number_map : mapToObject(myMap)
      };
      var apiRequest = apiUtility.apiInputRequest;
      apiRequest.endPoint = BASE_URL_USER_UPDATE + params;
      apiRequest.data = newMap;
      const res = await apiUtility.updateAsyncAPIDataPUT(apiRequest);
      const resJson = res.data.data;
      setShowSpinner(false);
      setShowCnumError("");
      console.log(res);

      if (resJson === undefined) {
        setshowDangerAlert(true);
        setshowSuccessAlert(false);
        setErrorMsg("Something went wrong! Please try again.");
        setShowErrorMsg(true);
      }
      else if(resJson === 'Username or Email Already in use!' || resJson === 'User details Already in use!'){
        setshowDangerAlert(true);
        setshowSuccessAlert(false);
        setErrorMsg("Username or Email Already in use!");
        setShowErrorMsg(true);
      }
       else {
        sessionStorage.removeItem("tempUserTrainingData");

        setShowSuccess(true);
        setsuccessMsg("Record updated successfully!");
        setshowDangerAlert(false);
        setshowSuccessAlert(true);
        setValidated(true);
        for (var [tp_id, value] of myMap) {
              myMap.set(tp_id, false); 
        }
      }
      var newMap = {
        userObj: newUser,
      };


    } catch (error) {
      setShowSpinner(false);
      setErrorMsg("Something went wrong! Please try again.");
      setShowErrorMsg(true);
      console.log(error);
    }
  };

  const handleConsentCallback = (result) => {
    setConsentDialog(false);
    //console.log(arrayConsent,newUserTrainingData);

    if (String(arrayConsent) != "") {
      var tempTrainingData = [...newUserTrainingData];
      tempTrainingData[arrayConsent].consent = result;
      setNewUserTrainingData(tempTrainingData);
      sessionStorage.setItem(
        "tempUserTrainingData",
        JSON.stringify(tempTrainingData)
      );
    }
    else {
      var tempConsentArray = [...newUserTrainingData];
      //console.log(tempConsentArray)
      var tempConsentObj = { ...tempConsentArray[tempConsentArray.length - 1] };
      tempConsentObj.consent = result;
      tempConsentArray[tempConsentArray.length - 1] = tempConsentObj;
      //console.log(tempConsentArray);
      setNewUserTrainingData(tempConsentArray);
      //console.log(tempConsentArray);
      setNewUserTrainingData(tempConsentArray);
      sessionStorage.setItem(
        "tempUserTrainingData",
        JSON.stringify(tempConsentArray)
      );
      setShowForm(false);
    }
    setArrayConsent("")
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setshowDangerAlert(false);
    setshowSuccessAlert(false);
    setNewUser({ ...newUser, [name]: value });
    const length = value.length <= 0;
    //console.log(name, value);

    if (name === "country") {
      setSelectedCountry(value);
      setSelectedState(-1);
      newUser.state = "";
    }
    if (name === "state") {
      setSelectedState(value);
    }

    var firstName = errors["firstName"];
    var userName = errors["userName"];
    var email = errors["email"];
    var dob = errors["dob"];
    var number = errors["number"];
    var firstNameDesc = errorText["firstName"];
    var userNameDesc = errorText["userName"];
    var emailDesc = errorText["email"];
    var dobDesc = errorText["dob"];
    var numberDesc = errorText["number"];
    var cnum = errors["cnum"];
    var cnumDesc = errorText["cnum"];
    var country = errors["country"];
    var countryDesc = errorText["country"];
    var state = errors["state"];
    var stateDesc = errorText["state"];
    var city = errors["city"];
    var cityDesc = errorText["city"];
    var postal = errors["postal"];
    var postalDesc = errorText["postal"];
    var address = errors["address"];
    var addressDesc = errorText["address"];
    var gender = errors["gender"];
    var genderDesc = errorText["gender"];

    if (name === "firstName") {
      errorText["firstName"] = "";
      firstNameDesc = "";
      firstName = false;
      if (!value.match(/^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<, -]*$/i)) {
        firstNameDesc = "Only letters are allowed";
        errorText["firstName"] = firstNameDesc;
        firstName = true;
      }
      errors["firstName"] = firstName;
    }
    if (name === "userName") {
      userNameDesc = "";
      userName = false;
      errorText["userName"] = "";
      if (length) {
        userName = true;
        errorText["userName"] = "Username is mandatory";
      } else {
        if (!value.match(/^[a-zA-Z0-9]+$/)) {
          userNameDesc = "Only alpha numeric allowed";
          errorText["userName"] = userNameDesc;
          userName = true;
        }
      }
      errors["userName"] = userName;
    }
    if (name === "email") {
      emailDesc = "";
      email = false;
      errorText["email"] = "";
      if (length) {
        email = true;
        emailDesc = "Email cannot be left empty";
        errorText["email"] = "Email cannot be left empty";
      } else {
        if (!validateEmail(value)) {
          email = true;
          emailDesc = "Email is not valid";
          errorText["email"] = "Email is not valid";
        }
      }
      errors["email"] = email;
    }
    if (name === "dob") {
      dobDesc = "";
      dob = false;
      errorText["dob"] = "";
      if (length) {
        dob = true;
        dobDesc = "Date of Birth is mandatory";
        errorText["dob"] = "Date of Birth is mandatory";
      } else {
        if (calAge(value) < 10) {
          dob = true;
          dobDesc = "Should be min 10 years old";
          errorText["dob"] = "Should be 10 years old";
        }else if(isInvalidYears(value)){
          dob = true;
          dobDesc = "Invalid years!";
          errorText["dob"] = "Invalid years!";
        }
      }
      errors["dob"] = dob;
    }

    if (name === "gender") {
      errorText["gender"] = "";
      genderDesc = "";
      gender = false;
      if (value === -1 || value === "-1") {
        genderDesc = "Gender is mandatory";
        errorText["gender"] = "Gender is mandatory";
        gender = true;
      }
      errors["gender"] = gender;
    }

    if (name === "number") {
      errorText["number"] = "";
      numberDesc = "";
      number = false;
      if (length) {
        numberDesc = "Contact number is mandatory";
        errorText["number"] = "Contact number is mandatory";
        number = true;
      } else {
        if (!value.match(/^\d+$/)) {
          numberDesc = "Only numbers are allowed";
          errorText["number"] = numberDesc;
          number = true;
        }
      }
      errors["number"] = number;
    }

    if (name === "country") {
      errorText["country"] = "";
      countryDesc = "";
      country = false;
      if (value === -1 || value === "-1") {
        countryDesc = "Country is mandatory";
        errorText["country"] = "Country is mandatory";
        country = true;
      }
      errors["country"] = country;
    }
    if (name === "state") {
      errorText["state"] = "";
      stateDesc = "";
      state = false;
      if (value === -1 || value === "-1") {
        stateDesc = "State is mandatory";
        errorText["state"] = "State is mandatory";
        state = true;
      }
      errors["state"] = state;
    }
    if (name === "city") {
      errorText["city"] = "";
      cityDesc = "";
      city = false;
      if (length) {
        cityDesc = "City is mandatory";
        errorText["city"] = "City is mandatory";
        city = true;
      }
      errors["city"] = city;
    }

    if (name === "postalCode") {
      errorText["postal"] = "";
      postalDesc = "";
      postal = false;
      if (length) {
        postalDesc = "Postal Code is mandatory";
        errorText["postal"] = "Postal Code is mandatory";
        postal = true;
      }
      const postalCodeRegex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;

        if (postalCodeRegex.test(value)) {
          console.log("Postal code is valid.");
        } else {
          postalDesc = "Postal Code should be of format LNL NLN";
          errorText["postal"] = "Postal Code should be of format LNL NLN";
          postal = true;        }
          errors["postal"] = postal;
          
    }

    if (name === "addressLine1") {
      errorText["address"] = "";
      addressDesc = "";
      address = false;
      if (length) {
        addressDesc = "Address Line 1 is mandatory";
        errorText["address"] = "Address Line 1 is mandatory";
        address = true;
      }
      errors["address"] = address;
    }

    // if(name === 'cnum'){
    //     cnumDesc = "";
    //     cnum=false;
    //     errorText["cnum"] = "";
    //     if(!length){
    //         if (!value.match(/^[a-zA-Z0-9]+$/)) {
    //             cnumDesc = "Only alpha numeric allowed";
    //             errorText["cnum"] = cnumDesc;
    //             cnum = true;
    //         }else{
    //             let cNumObj = { cnumFlag: cnum, cnumDesc: cnumDesc };
    //             validateLambtonId(value, cNumObj);
    //             cnum = cNumObj.cnumFlag;
    //             cnumDesc = cNumObj.cnumDesc;
    //         }
    //         // setCnum
    //     }
    //     errors["cnum"]=cnum;
    // }
    // if(name === 'trainingProvider'){

    //     errorText["cnum"] = "";
    //     if(!length){
    //         if (!value.match(/^[a-zA-Z0-9]+$/)) {
    //             cnumDesc = "Only alpha numeric allowed";
    //             errorText["cnum"] = cnumDesc;
    //             cnum = true;
    //         }else{
    //             let cNumObj = { cnumFlag: cnum, cnumDesc: cnumDesc };
    //             validateLambtonId(value, cNumObj);
    //             cnum = cNumObj.cnumFlag;
    //             cnumDesc = cNumObj.cnumDesc;
    //         }
    //         setCnum
    //     }
    //     errors["cnum"]=cnum;
    // }
    setValidationError({
      ...validationError,
      firstName,
      userName,
      email,
      dob,
      number,
      cnum,
      country,
      state,
      city,
      postal,
      gender,
      address,
    });
    setErrorDesc({
      ...errorDesc,
      firstNameDesc,
      userNameDesc,
      emailDesc,
      dobDesc,
      numberDesc,
      cnumDesc,
      countryDesc,
      stateDesc,
      cityDesc,
      postalDesc,
      genderDesc,
      addressDesc,
    });

    if (name === "country") {
      setSelectedCountry(value);
    }

    if (name === "city") {
      setSelectedCity(value);
    }

    if (name === "state") {
      setSelectedState(value);
    }
  };

  const validateLambtonId = (value, cNumObj) => {
    const regex = /^[abctz]/i;
    var cnumDesc = "";
    if (regex.test(value)) {
      if (
        (value.startsWith("c", 0) || value.startsWith("C", 0)) &&
        value.length !== 8
      ) {
        cnumDesc =
          "Lambton ID starting with 'c0' should contain no more or less than 6 digits.";
        errorText["cnum"] = cnumDesc;
        cNumObj.cnumFlag = true;
        cNumObj.cnumDesc = cnumDesc;
      }
      if (
        (value.startsWith("Z", 0) || value.startsWith("z", 0)) &&
        value.length !== 4 &&
        value.length !== 5
      ) {
        cnumDesc =
          "Lambton ID starting with 'z' should contain no more than 4 or less than 3 digits.";
        errorText["cnum"] = cnumDesc;
        cNumObj.cnumFlag = true;
        cNumObj.cnumDesc = cnumDesc;
      }
      if (
        (value.startsWith("a", 0) ||
          value.startsWith("b", 0) ||
          value.startsWith("t", 0) ||
          value.startsWith("A", 0) ||
          value.startsWith("B", 0) ||
          value.startsWith("T", 0)) &&
        value.length !== 4
      ) {
        cnumDesc =
          "Lambton ID starting with 'a' OR 'b' OR 't' should contain no more or less than 4 digits.";
        errorText["cnum"] = cnumDesc;
        cNumObj.cnumFlag = true;
        cNumObj.cnumDesc = cnumDesc;
      }
    } else {
      cnumDesc =
        "Lambton ID should starts with 'a' OR 'b' OR 'c' OR 't' OR 'z'";
      errorText["cnum"] = cnumDesc;
      cNumObj.cnumFlag = true;
      cNumObj.cnumDesc = cnumDesc;
    }
  };

  const calAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const isInvalidYears = (dateString) => {
    const birthDate = new Date(dateString);
    if(birthDate.getFullYear() < 1900){
      return true;
    }
    return false;
  };

  const validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  };

  const handleUpdateCallback = (result) => {
    setshowPassword(false);
    setsuccessMsg(result);
    setShowSuccess(true);
  };

  const handleDeactivationCallback = (result) => {
    setShowDeactivation(false);
    if (result === "Account deactivated successfully!") {
      if (
        sessionStorage.getItem("userType") !== undefined &&
        sessionStorage.getItem("userType") !== "admin"
      ) {
        setsuccessMsg(result + " Logging you out in 5 Seconds...");
        setShowSuccess(true);
        setTimeout(() => {
          apiUtility.logOut();
          return (window.location.href = BASE_URL_FRONTEND);
        }, 5000);
      } else {
        setsuccessMsg(result);
        setShowSuccess(true);
        setActiveStatus(false);
        setVerifiedStatus(false);
      }
    }
  };

  const validateDate = (value) => {
    var number = errors["number"];
    var numberDesc = errorText["number"];
    errorText["number"] = "";
    numberDesc = "";
    validationError["number"] = false;
    number = false;
    if (value === undefined || value === "") {
      numberDesc = "Contact number is mandatory";
      errorText["number"] = "Contact number is mandatory";
      number = true;
    } else {
      if (!isPossiblePhoneNumber(value)) {
        numberDesc = "Invalid Contact Number";
        errorText["number"] = "Invalid Contact Number";
        number = true;
      }
    }
    newUser.number = value;
    errors["number"] = number;
    validationError.number = number;
    errorDesc.number = numberDesc;
  };

  const validateMandatoryOnSubmit = (newUser) => {
    var error = false;
    if (newUser.email === "" && newUser.email.length <= 0) {
      errors["email"] = true;
      validationError["email"] = true;
      errorText["email"] = "Email cannot be left empty";
      errorDesc["emailDesc"] = "Email cannot be left empty";
    }
    if (newUser.dob === "" && newUser.dob.length <= 0) {
      errors["dob"] = true;
      validationError["dob"] = true;
      errorText["dob"] = "Date of Birth is mandatory";
      errorDesc["dobDesc"] = "Date of Birth is mandatory";
    }
    if (
      newUser.gender === "" ||
      newUser.gender === "-1" ||
      newUser.gender === -1
    ) {
      errors["gender"] = true;
      validationError["gender"] = true;
      errorText["gender"] = "Gender is mandatory";
      errorDesc["genderDesc"] = "Gender is mandatory";
    }
    if (newUser.number === "" && newUser.number.length <= 0) {
      errors["number"] = true;
      validationError["number"] = true;
      errorText["number"] = "Contact Number is mandatory";
      errorDesc["numberDesc"] = "Contact Number is mandatory";
    }
    if (
      newUser.country === "" ||
      newUser.country === "-1" ||
      newUser.country === -1
    ) {
      errors["country"] = true;
      validationError["country"] = true;
      errorText["country"] = "Country is mandatory";
      errorDesc["countryDesc"] = "Country is mandatory";
    }
    if (
      newUser.state === "" ||
      newUser.state === "-1" ||
      newUser.state === -1
    ) {
      errors["state"] = true;
      validationError["state"] = true;
      errorText["state"] = "State is mandatory";
      errorDesc["stateDesc"] = "State is mandatory";
    }
    if (newUser.city === "" && newUser.city.length <= 0) {
      errors["city"] = true;
      validationError["city"] = true;
      errorText["city"] = "City is mandatory";
      errorDesc["cityDesc"] = "City is mandatory";
    }
    if (newUser.postalCode === "" && newUser.postalCode.length <= 0) {
      errors["postal"] = true;
      validationError["postal"] = true;
      errorText["postal"] = "Postal is mandatory";
      errorDesc["postalDesc"] = "Postal is mandatory";
    }
    if (newUser.addressLine1 === "" && newUser.addressLine1.length <= 0) {
      errors["address"] = true;
      validationError["address"] = true;
      errorText["address"] = "Address Line 1 is mandatory";
      errorDesc["addressDesc"] = "Address Line 1 is mandatory";
    }

    if (newUser.userName === "" && newUser.userName.length <= 0) {
      errors["userName"] = true;
      validationError["userName"] = true;
      errorText["userName"] = "Username is mandatory";
      errorDesc["userNameDesc"] = "Username is mandatory";
    }

    if (
      newUser.firstName === "" &&
      newUser.firstName.length <= 0 &&
      newUser.lastName === "" &&
      newUser.lastName.length <= 0
    ) {
      errors["firstName"] = true;
      validationError["firstName"] = true;
      errorText["firstName"] =
        "User's first or last name is mandatory. Both fields cannot be left empty.";
      errorDesc["firstNameDesc"] =
        "User's first or last name is mandatory. Both fields cannot be left empty.";
    }
    if (studentData.providerName != "" && String(studentData.providerName) !="-1" && studentData.providerName != undefined && studentData.consent == false) {
      setErrorMsg(
        "Please fill in the training provider details and consent before submitting."
      );
      setShowErrorMsg(true);
      return true;
    }
    var tempUserTrainingData;
    if (sessionStorage.getItem("tempUserTrainingData")) {
      setNewUserTrainingData(
        JSON.parse(sessionStorage.getItem("tempUserTrainingData"))
      );
      tempUserTrainingData = JSON.parse(
        sessionStorage.getItem("tempUserTrainingData")
      );
    } else {
      tempUserTrainingData = newUserTrainingData;
    }
    if (errorText["cnum"] != "") {
      setErrorMsg(
        "Incorrect or missing details. Please update highlighted fields value to proceed further."
      );
      setShowErrorMsg(true);
      return true;
    }
    if (tempUserTrainingData.length != 0) {
      var match = false;
      for (var i = 0; i < tempUserTrainingData.length; i++) {
        if (tempUserTrainingData[i].consent == false) {
          setErrorMsg(
            "Please Consent to agreement for all training providers to continue."
          );
          setShowErrorMsg(true);
          return true;
        }
        if(String(tempUserTrainingData[i].tp_id) == "-1"){
          setErrorMsg(
            "Please select a training provider to continue."
          );
          setShowErrorMsg(true);
          return true;
        }
        if (tempUserTrainingData[i].enrollCheck == false) {
          var provData = JSON.parse(sessionStorage.getItem("training_providers"));
          var getRegex = provData.find(
            (item) => item.tp_id === parseInt(tempUserTrainingData[i].tp_id)
          );
          const regex = new RegExp(getRegex.student_number_regex);
          match = regex.test(tempUserTrainingData[i].num);

          if (tempUserTrainingData[i].num.length == 0) {
            console.log(tempUserTrainingData[i]);
            match = false;
            let errors = {};
            errors[`cnum-${i}`] = "*Student no. string cannot be empty for " + getRegex.provider_name;
            setTrainingErrorText(errors);
            // errorText["cnum"] = "*student no. string cannot be empty for " + getRegex.provider_name
            // setShowCnumError("*student no. string cannot be empty for "+getRegex.provider_name);
            setErrorMsg(
              "Incorrect or missing details. Please update highlighted fields value to proceed further."
            );
            setShowErrorMsg(true);
            return true;

            break;
          }
          else {
            errorText["cnum"] = "";
            setShowCnumError("");
            //var errors = {};
            errors[`cnum-${i}`] = "";
            setTrainingErrorText(errors);
          }
          if (!match) {
            //var errors = {};
            errors[`cnum-${i}`] = ("*Regex does not match student no. string for " + getRegex.provider_name);;
            setTrainingErrorText(errors);
            //errorText["cnum"] = ("*Regex does not match student no. string for " + getRegex.provider_name);
            setErrorMsg(
              "Incorrect or missing details. Please update highlighted fields value to proceed further."
            );
            setShowErrorMsg(true);
            return true;
            break;
          }
        }
      }
      if (match) {
        setShowCnumError("");
        errorText["cnum"] = "";
      }
    }

    if (Object.values(validationError).includes(true)) {
      setErrorMsg(
        "Incorrect or missing details. Please update highlighted fields value to proceed further."
      );
      setShowErrorMsg(true);
      error = true;
    }
    return error;
  };

  const validateConsentOnEnrollment = (newUser) => {
    var error = false;
    if (
      newUser.cnum !== "" &&
      newUser.cnum.length > 0 &&
      newUser.consent === false
    ) {
      setConsentDialog(true);
      error = true;
    }
    return error;
  };

  const activateAccountForUser = () => {
    //const json = JSON.stringify({ login_id: newUser.userName});
    //axios.post(BASE_API_URL+"login/activate", json, {headers})
    var apiRequest = apiUtility.apiInputRequest;
    apiRequest.endPoint = "login/activate";
    apiRequest.data = { login_id: newUser.userName };
    apiUtility
      .sendAPIDataPOST(apiRequest)
      .then((res) => {
        if (res.data.msg != "Cannot fetch user info!") {
          setshowPassword(false);
          setsuccessMsg(res.data.msg);
          setShowSuccess(true);
          setActiveStatus(true);
        } else {
          setErrorMsg(res.data.msg);
          setShowErrorMsg(true);
        }
      })
      .catch((err) => {
        setErrorMsg("Something went wrong!");
        setShowErrorMsg(true);
        console.log(err);
      });
  };

  // New functions for Multiple training Provider logic
  function handleOnChangeTP(ev, txt) {
    //console.log(ev.target.value, txt);
    var tempTrainingData = {};
    tempTrainingData = { ...studentData };
    if (txt == "providerName" && (String(ev.target.value) != "-1")) {
      tempTrainingData.providerName = ev.target.value;
      tempTrainingData.studentNo = "";
      tempTrainingData.consent = false;
      tempTrainingData.enrollCheck = !(trainingProviders.find(
        (item) => String(item.tp_id) === String(studentData.providerName)
      )?.student_number_check);
      errorText["cnum"] = "";
      setShowCnumError("");
      updateMap(parseInt(ev.target.value),true);
      //setStudentData(tempTrainingData);
    }
    
    else if(txt == "remove" && newUserTrainingData.length!=0){
      setStudentData({});
      setShowForm(false);
    }
    else if(txt == "remove" && studentData.providerName && studentData.providerName!=undefined && newUserTrainingData.length==0){

      setStudentData({});
       setShowForm(true);
    }

    else if (txt == "consentCheck") {
      tempTrainingData.consent = ev.target.checked;
      // setStudentData(tempTrainingData);
      setConsentDialog(true);
      //console.log(studentData)
      if (studentData.providerName &&
        studentData.providerName != "-1" &&
        !studentData.studentNo) {

        var tempStudentData = { ...studentData };
        tempStudentData.studentNo = "";
        tempStudentData.consent = false;
        var tempUserTrainingData;
        if (sessionStorage.getItem("tempUserTrainingData") && JSON.parse(sessionStorage.getItem("tempUserTrainingData")).length > 0) {
          tempUserTrainingData = JSON.parse(
            sessionStorage.getItem("tempUserTrainingData")
          );
          setShowCnumError("");

          tempUserTrainingData.push({
            tp_id: parseInt(studentData.providerName),
            num: "",
            consent: false,
            enrollCheck: studentData.enrollCheck ? studentData.enrollCheck : !(trainingProviders.find(
              (item) => String(item.tp_id) === String(studentData.providerName)
            )?.student_number_check)
          });
          setNewUserTrainingData(tempUserTrainingData);
          sessionStorage.setItem(
            "tempUserTrainingData",
            JSON.stringify(tempUserTrainingData)
          );
          setConsentDialog(true);
          setShowForm(false);
          setStudentData({});
        }
        else if (newUserTrainingData.length > 0) {
          tempUserTrainingData = [...newUserTrainingData];
          tempUserTrainingData.push({
            tp_id: parseInt(studentData.providerName),
            num: "",
            consent: false,
            enrollCheck: studentData.enrollCheck ? studentData.enrollCheck : !(trainingProviders.find(
              (item) => String(item.tp_id) === String(studentData.providerName)
            )?.student_number_check)
          });
          setNewUserTrainingData(tempUserTrainingData);
          sessionStorage.setItem(
            "tempUserTrainingData",
            JSON.stringify(tempUserTrainingData)
          );
          setConsentDialog(true);
          setShowForm(false);
          setStudentData({});
        }
        else {

          var tempUserTrainingData = [];
          setShowCnumError("");

          tempUserTrainingData.push({
            tp_id: parseInt(studentData.providerName),
            num: studentData.studentNo ? studentData.studentNo.toLowerCase() : "",
            consent: false,
            enrollCheck: studentData.enrollCheck ? studentData.enrollCheck : !(trainingProviders.find(
              (item) => String(item.tp_id) === String(studentData.providerName)
            )?.student_number_check)
          });
          setNewUserTrainingData(tempUserTrainingData);
          sessionStorage.setItem(
            "tempUserTrainingData",
            JSON.stringify(tempUserTrainingData)
          );

          setConsentDialog(true);
          setShowForm(false);
          setStudentData({});
        }
      }
      else if (
        studentData.studentNo &&
        studentData.providerName &&
        studentData.providerName != "-1"
      ) {

        var tempStudentData = { ...studentData };
        tempStudentData.studentNo = studentData.studentNo.toLowerCase();
        tempStudentData.consent = false;
        var tempUserTrainingData;
        if (sessionStorage.getItem("tempUserTrainingData") && JSON.parse(sessionStorage.getItem("tempUserTrainingData")).length > 0) {
          tempUserTrainingData = JSON.parse(
            sessionStorage.getItem("tempUserTrainingData")
          );
          setShowCnumError("");

          tempUserTrainingData.push({
            tp_id: parseInt(studentData.providerName),
            num: tempStudentData.studentNo.toLowerCase(),
            consent: false,
            enrollCheck: studentData.enrollCheck ? studentData.enrollCheck : !(trainingProviders.find(
              (item) => String(item.tp_id) === String(studentData.providerName)
            )?.student_number_check)
          });
          setNewUserTrainingData(tempUserTrainingData);
          sessionStorage.setItem(
            "tempUserTrainingData",
            JSON.stringify(tempUserTrainingData)
          );
          setConsentDialog(true);
          setShowForm(false);
          setStudentData({});
        }
        else if (newUserTrainingData.length > 0) {
          tempUserTrainingData = [...newUserTrainingData];
          tempUserTrainingData.push({
            tp_id: parseInt(studentData.providerName),
            num: tempStudentData.studentNo.toLowerCase(),
            consent: false,
            enrollCheck: studentData.enrollCheck ? studentData.enrollCheck : !(trainingProviders.find(
              (item) => String(item.tp_id) === String(studentData.providerName)
            )?.student_number_check)
          });
          setNewUserTrainingData(tempUserTrainingData);
          sessionStorage.setItem(
            "tempUserTrainingData",
            JSON.stringify(tempUserTrainingData)
          );
          setConsentDialog(true);
          setShowForm(false);
          setStudentData({});
        }
        else {

          var tempUserTrainingData = [];
          setShowCnumError("");

          tempUserTrainingData.push({
            tp_id: parseInt(studentData.providerName),
            num: studentData.studentNo ? studentData.studentNo.toLowerCase() : "",
            consent: false,
            enrollCheck: studentData.enrollCheck ? studentData.enrollCheck : !(trainingProviders.find(
              (item) => String(item.tp_id) === String(studentData.providerName)
            )?.student_number_check)
          });
          setNewUserTrainingData(tempUserTrainingData);
          sessionStorage.setItem(
            "tempUserTrainingData",
            JSON.stringify(tempUserTrainingData)
          );

          setConsentDialog(true);
          setShowForm(false);
          setStudentData({});
        }
      }
      else {
        alert("Please fill all fields!");
      }
      updateMap(parseInt((studentData.providerName)),true);
    }

    //console.log(newUserTrainingData)

    else if(txt == "studentNo"){
      tempTrainingData.studentNo = ev.target.value.toLowerCase();
      tempTrainingData.enrollCheck = false;

      //var errors = {};
      var provData = JSON.parse(sessionStorage.getItem("training_providers"));
      var getRegex = provData.find(
        (item) => item.tp_id === parseInt(studentData.providerName)
      );
      //console.log(getRegex);
      const regex = new RegExp(getRegex.student_number_regex);
      const match = regex.test(tempTrainingData.studentNo.toLowerCase());
      if (getRegex.student_number_length && parseInt((ev.target.value).length) != parseInt(getRegex.student_number_length)) {
        errorText["cnum"] = "*Student number length must be " + getRegex.student_number_length + "characters long";

      }
      else {
        if (!match) {
          // setShowNewCnumError("Invalid student number format");
          //  errors["cnum"] = true;

          errorText["cnum"] = "*Student no. does not match with the format for " + getRegex.provider_name;
          // setShowCnumError(errors);
          // console.log(showCnumError)

        }
        else {
          errorText["cnum"] = ""
          // setShowCnumError("");
        }
      }

      // tempTrainingData.studentNo = ev.target.value;
      //setStudentData(tempTrainingData);
      //console.log(tempTrainingData);
    }
    setStudentData(tempTrainingData);
  }

  function handleOnChangeTPArray(ev,user, txt, id) {
    //var errors = {}
    //console.log("newUserTrainingData1", newUserTrainingData);

    var tempTrainingData;
    tempTrainingData = [...newUserTrainingData];
    if (txt == "providerName" && (String(ev.target.value) != "-1")) {
      tempTrainingData[id].consent = false;


      if (newUserTrainingData.find((item) => parseInt(item.tp_id) === parseInt(ev.target.value))
      ) {
        setErrorMsg("This training Provider is already selected!");
        setShowErrorMsg(true);
      } else {
        updateMap(parseInt(tempTrainingData[id].tp_id),true);
        addToMap(parseInt(ev.target.value),true);
        //var errors = {}
          tempTrainingData[id].enrollCheck = !(trainingProviders.find((item) => String(item.tp_id) === String(ev.target.value))?.student_number_check);
          tempTrainingData[id].tp_id = parseInt(ev.target.value);
          tempTrainingData[id].num = "";
          tempTrainingData[id].consent = false;
          errors[`cnum-${id}`] = "";
          setShowCnumError("");
          setTrainingErrorText(errors);
          // Update state and sessionStorage
          // setNewUserTrainingData(tempTrainingData);
          // sessionStorage.setItem("tempUserTrainingData", JSON.stringify(tempTrainingData));
        
       

      }
    }
    else if (txt == "consent") {
      //console.log(ev.target.checked)
      updateMap(parseInt(tempTrainingData[id].tp_id),true);
      setArrayConsent(id);
      setConsentDialog(true);

    }
    else if(txt=="cnum") {
      // let tempRegexCheck = newUserTrainingData[id].tp_id;
      var provData = JSON.parse(sessionStorage.getItem("training_providers"));
      var getRegex = provData.find(
        (item) => parseInt(item.tp_id) === parseInt(newUserTrainingData[id].tp_id)
      );
      //console.log(getRegex);
      const regex = new RegExp(getRegex.student_number_regex);
      const match = regex.test(ev.target.value.toLowerCase());
      tempTrainingData[id].num = String(ev.target.value).toLowerCase();
      tempTrainingData[id].enrollCheck = false;
      tempTrainingData[id].consent = false;

      if (getRegex.student_number_length && parseInt(tempTrainingData[id].num.length) != parseInt(getRegex.student_number_length)) {
        setShowCnumError("*Student number length for " + getRegex.provider_name + " must be " + getRegex.student_number_length + " characters long");
        // errors[`cnum-${id}`] = 'Invalid student number';
        errors[`cnum-${id}`] = "*Student number length for " + getRegex.provider_name + " must be " + getRegex.student_number_length + " characters long";
        setTrainingErrorText(errors);
      }
      else {
        if (match) {
          //setNewUserTrainingData(tempTrainingData);
          if (showCnumError) {
            if (
              showCnumError.includes(getRegex.provider_name) ||
              showCnumError.includes(ev.target.value.toLowerCase())
            ) {
              setShowCnumError("");
              errors[`cnum-${id}`] = "";
              setTrainingErrorText(errors);
            }
          }
          if (trainingErrorText[`cnum-${id}`] != "") {
            errors[`cnum-${id}`] = "";
            setTrainingErrorText(errors);
          }

          // sessionStorage.setItem('tempUserTrainingData', JSON.stringify(tempTrainingData));
        } else {
          // sessionStorage.setItem('tempUserTrainingData', JSON.stringify(tempTrainingData));
          setShowCnumError(
            "*Student no. " + ev.target.value.toLowerCase() +

            " does not match with the format for " + getRegex.provider_name

          );
          // errors[`cnum-${id}`] = 'Invalid student number';
          errors[`cnum-${id}`] = "*Student no. " + ev.target.value.toLowerCase() + " does not match with the format for " + getRegex.provider_name;
          setTrainingErrorText(errors);
          //console.log(errors,trainingErrorText)
          // setShowCnumError('*Regex does not match student no. string for '+getRegex.provider_name);
        }
      }

      
    }
    setNewUserTrainingData(tempTrainingData);
    sessionStorage.setItem(
      "tempUserTrainingData",
      JSON.stringify(tempTrainingData)
    );
  }
// Function to add a key-value pair to the map
const addToMap = (key, value) => {
  
  setMyMap(prevMap => {
    // Step 2: Create a new map based on the previous state
    const newMap = new Map(prevMap);
    newMap.set(key, value); // Add a new key-value pair
    return newMap;
  });
};


const updateMap = (key, value) => {
  setMyMap(prevMap => {
    const newMap = new Map(prevMap);
      newMap.set(key, value); 
    return newMap;
  });
 
};
 

  const handleAddNewField = (e, user, txt, index) => {
    if (txt === "remove") {
      const updatedItems = newUserTrainingData.filter(
        (item) => parseInt(item.tp_id) !== parseInt(user.tp_id)
      );
      const updatedConsentArray = consentArray.filter(item => parseInt(item.tp_id) !== parseInt(user.tp_id));
      setConsentArray(updatedConsentArray);
      sessionStorage.setItem(
        "tempUserTrainingData",
        JSON.stringify(updatedItems)
      );
      setNewUserTrainingData(updatedItems);
      var provData = JSON.parse(sessionStorage.getItem("training_providers"));
      var getRegex = provData.find(
        (item) => item.tp_id === parseInt(user.tp_id)
      );
      if (showCnumError) {
        if (
          showCnumError.includes(getRegex.provider_name) &&
          showCnumError.includes(user.num)
        ) {
          setShowCnumError("");
        }
      }
      if (updatedItems.length < 1) {
        setShowForm(true);
      }
    } else {
      setShowForm(true);
    }
  };
  // function handleValidate() {
  //   console.log(studentData);
  //   if (
  //     studentData.studentNo &&
  //     studentData.providerName &&
  //     studentData.providerName != "-1"
  //   ) {
  //     var provData = JSON.parse(sessionStorage.getItem("training_providers"));
  //     var getRegex = provData.find(
  //       (item) => item.tp_id === parseInt(studentData.providerName)
  //     );
  //     console.log(getRegex);
  //     const regex = new RegExp(getRegex.student_number_regex);
  //     const match = regex.test(studentData.studentNo);
  //     if (match) {
  //       var tempUserTrainingData = [...newUserTrainingData];
  //       if (sessionStorage.getItem("tempUserTrainingData")) {
  //         tempUserTrainingData = JSON.parse(
  //           sessionStorage.getItem("tempUserTrainingData")
  //         );
  //       }
  //       tempUserTrainingData.push({
  //         tp_id: parseInt(studentData.providerName),
  //         num: studentData.studentNo,
  //       });
  //       setNewUserTrainingData(tempUserTrainingData);
  //       sessionStorage.setItem(
  //         "tempUserTrainingData",
  //         JSON.stringify(tempUserTrainingData)
  //       );
  //       setShowForm(false);
  //       setStudentData({});
  //       setValidateCnumError(false);
  //       setShowValidRegex(true);
  //       setShowIncompleteFieldsError(false);
  //     } else {
  //       //setShowCnumError(true);
  //       setShowValidRegex(false);
  //       setValidateCnumError(true);
  //       setShowIncompleteFieldsError(false);
  //     }
  //   } else {
  //     setShowIncompleteFieldsError(true);
  //   }
  // }
  return (
    <div>
      <Container>
        <Row>
          <Row className="mb-12">
            <Col className="text-right">
              <h5 className="text-right">
                User ID: {params} &nbsp;
                {sessionStorage.getItem("userType") !== undefined &&
                  sessionStorage.getItem("userType") === "admin" ? (
                  verifiedStatus === true || verifiedStatus === "true" ? (
                    <SlUserFollowing
                      style={{
                        fontSize: "2pc",
                        color: "#0f6fc5",
                        float: "right",
                      }}
                    />
                  ) : (activeStatus === false || activeStatus === "false") &&
                    (verifiedStatus === false || verifiedStatus === "false") ? (
                    <SlUserUnfollow
                      style={{ fontSize: "2pc", color: "red", float: "right" }}
                    />
                  ) : (
                    <SlUserUnfollow
                      style={{
                        fontSize: "2pc",
                        color: "#0f6fc5",
                        float: "right",
                      }}
                    />
                  )
                ) : (
                  ""
                )}
              </h5>
              <br></br>
            </Col>
          </Row>
          <Form noValidate validated={validated}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="input"
                  name="firstName"
                  onChange={handleOnChange}
                  isInvalid={!!errorText["firstName"]}
                  value={newUser.firstName}
                  placeholder="Enter your given name"
                />
                {/* <span className='text-danger'>{errorText["firstName"]}</span> */}
                <Form.Control.Feedback type="invalid">
                  {errorText["firstName"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridName">
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  type="input"
                  name="middleName"
                  onChange={handleOnChange}
                  value={newUser.middleName}
                  placeholder="Enter your middle name"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="input"
                  name="lastName"
                  onChange={handleOnChange}
                  value={newUser.lastName}
                  placeholder="Enter your last name"
                />
              </Form.Group>
            </Row>
            <br></br>
            <Row>
              <Form.Group as={Col} controlId="formUserName">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="input"
                  name="userName"
                  onChange={handleOnChange}
                  isInvalid={!!errorText["userName"]}
                  value={newUser.userName}
                  placeholder="Enter your username"
                />
                {/* <span className='text-danger'>{errorText["userName"]}</span> */}
                <Form.Control.Feedback type="invalid">
                  {errorText["userName"]}
                </Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Group as={Col} controlId="formCNum">
                                <Form.Label>Lambton College Student Number&nbsp;
                                <Tooltip variant="contained" title="Your unique Lambton College ID can starts with : c0 +6 digits, z +4 digits, a +3 digits, b +3 digits, t +3 digits, z +3 digits" disableRipple>
                                    <Icon color='primary' style={{"fontSize":"15px"}}>
                                        <RxInfoCircled style={{"marginBottom":"9px"}}/>
                                    </Icon>
                                </Tooltip>

                                </Form.Label>
                                <Form.Control type="input" name="cnum" onChange={handleOnChange} isInvalid={!!errorText["cnum"]} value={newUser.cnum} placeholder="Enter your lambton C-Number" />
                                <Form.Control.Feedback type="invalid">{errorText["cnum"]}</Form.Control.Feedback>
                                
                            </Form.Group> */}
            </Row>
            <br></br>
            <Divider textAlign="left" role="presentation">
              <Typography level="body-sm" variant="caption" gutterBottom>
                Personal Details
              </Typography>
            </Divider>
            <br></br>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridDOB">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="dob"
                  onChange={handleOnChange}
                  isInvalid={!!errorText["dob"]}
                  value={newUser.dob}
                  placeholder="MM/dd/yyyy"
                />
                {/* <span className='text-danger'>{errorText["dob"]}</span> */}
                <Form.Control.Feedback type="invalid">
                  {errorText["dob"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridGender">
                <Form.Label>Gender</Form.Label>
                <Form.Select
                  name="gender"
                  value={newUser.gender}
                  isInvalid={!!errorText["gender"]}
                  onChange={handleOnChange}
                >
                  <option value="-1">--Choose Gender--</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="U">Unknown</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errorText["gender"]}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <br></br>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  isInvalid={!!errorText["email"]}
                  onChange={handleOnChange}
                  value={newUser.email}
                  placeholder="Enter email"
                />
                {/* <span className='text-danger'>{errorText["email"]}</span> */}
                <Form.Control.Feedback type="invalid">
                  {errorText["email"]}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPhoneNo">
                <Form.Label>Contact Number</Form.Label>
                <PhoneInput
                  placeholder="Enter contact number"
                  name="number"
                  defaultCountry="CA"
                  value={String(newUser.number)}
                  required={true}
                  onChange={validateDate}
                />
                <Form.Control.Feedback type="invalid">
                  {errorText["number"]}
                </Form.Control.Feedback>
                {validationError.number ? (
                  <div
                    className="text-danger"
                    style={{
                      marginLeft: "2.5pc",
                      fontSize: "0.8rem",
                      marginTop: "2px",
                    }}
                  >
                    {" "}
                    <p> {errorText["number"]} </p>
                  </div>
                ) : (
                  ""
                )}
              </Form.Group>
            </Row>
            <br></br>
            <Divider textAlign="left" role="presentation">
              <Typography level="body-sm" variant="caption" gutterBottom>
                Address Details
              </Typography>
            </Divider>
            <br></br>
            <Row className="mb-3">
              <Form.Group
                className="mb-3"
                as={Col}
                controlId="formGridAddressLine1"
              >
                <Form.Label>Address Line 1</Form.Label>
                <Form.Control
                  type="input"
                  name="addressLine1"
                  onChange={handleOnChange}
                  isInvalid={!!errorText["address"]}
                  value={newUser.addressLine1}
                  placeholder="Street address"
                />
                <Form.Control.Feedback type="invalid">
                  {errorText["address"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                as={Col}
                controlId="formGridAddressLine2"
              >
                <Form.Label>Address Line 2</Form.Label>
                <Form.Control
                  type="input"
                  name="addressLine2"
                  onChange={handleOnChange}
                  value={newUser.addressLine2}
                  placeholder="apt., suite, unit, building , floor, etc."
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>Country</Form.Label>
                <Form.Select
                  name="country"
                  value={newUser.country}
                  isInvalid={!!errorText["country"]}
                  onChange={handleOnChange}
                >
                  <option value="-1">--Choose Country--</option>
                  {data.countries.map((e, key) => {
                    return (
                      <option value={e.name} key={key}>
                        {e.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errorText["country"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label>State</Form.Label>
                <Form.Select
                  name="state"
                  value={newUser.state}
                  isInvalid={!!errorText["state"]}
                  onChange={handleOnChange}
                >
                  <option value="-1">--Choose State--</option>
                  {availableState?.states.map((e, key) => {
                    return (
                      <option value={e.name} key={key}>
                        {e.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errorText["state"]}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="input"
                  name="city"
                  onChange={handleOnChange}
                  isInvalid={!!errorText["city"]}
                  value={newUser.city}
                  placeholder="Enter your city"
                />
                {/* <Form.Select name="city" value={newUser.city} onChange={handleOnChange} >
                                    <option>--Choose City--</option>
                                    {availableCities?.cities.map((e, key) => {
                                        return (
                                            <option value={e.name} key={key}>
                                                {e}
                                            </option>
                                        );
                                    })}
                                </Form.Select> */}
                <Form.Control.Feedback type="invalid">
                  {errorText["city"]}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} controlId="formGridZip">
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type="input"
                  value={newUser.postalCode}
                  onChange={handleOnChange}
                  isInvalid={!!errorText["postal"]}
                  name="postalCode"
                  placeholder="Postal code"
                />
                <Form.Control.Feedback type="invalid">
                  {errorText["postal"]}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            
            <br></br>
            <Divider textAlign="left" role="presentation">
              <Typography level="body-sm" variant="caption" gutterBottom>
                Training Provider Details
              </Typography>
            </Divider>
           
            {newUserTrainingData?.map((user, index) => {
              return (<>
                <Row>
                  <Col xs={12} md={12}>
                    <br></br>
                    <Card
                      size="sm"
                      variant="plain"
                      orientation="horizontal"
                      sx={{ textAlign: 'center', overflow: 'auto', gap: 1, maxWidth: '100%', minWidth: '100%' }}>
                      <CardOverflow variant="solid" color="primary" sx={{ minWidth: '25%', flex: '0 0 200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', px: 'var(--Card-padding)' }}>
                        <Form.Group controlId={`trainingPV-${user.tp_id}`}>
                          <Form.Label>Training Provider</Form.Label>
                          <Form.Select
                            name="trainingProvider"
                            value={
                              trainingProviders?.find(
                                (item) => String(item.tp_id) === String(user.tp_id)
                              ).tp_id
                            }
                            isInvalid={!!trainingErrorText[`cnum-${index}`]}
                            onChange={(e) =>
                              handleOnChangeTPArray(e,user, "providerName", index)
                            }
                          >
                            <option value="-1">
                              --Choose Training Provider--
                            </option>
                            <option
                              value={
                                trainingProviders?.find(
                                  (item) => String(item.tp_id) === String(user.tp_id)
                                ).tp_id
                              }
                            >
                              {
                                trainingProviders?.find(
                                  (item) => String(item.tp_id) === String(user.tp_id)
                                ).provider_name
                              }
                            </option>
                            {trainingProviders?.map((e, key) => {
                              return newUserTrainingData.find(
                                (item) => item.tp_id == e.tp_id
                              ) == undefined ? (
                                <option value={e.tp_id} key={key}>
                                  {e.provider_name}
                                </option>
                              ) : (
                                ""
                              );
                            })}

                            { }
                          </Form.Select>
                        </Form.Group>
                      </CardOverflow>

                      <CardOverflow variant="soft" sx={{
                        minWidth: '60%', display: 'flex', flexDirection: 'row',
                        justifyContent: 'space-around', borderTop: '1px solid', borderBottom: '1px solid', borderColor: 'divider'
                      }}>
                        <Form.Group controlId={`formNumC-${index}`}>
                          <Form.Label>
                            Student Number&nbsp;
                            <Tooltip
                              variant="contained"
                              title={
                                !sessionStorage.getItem("training_providers")
                                  ? ""
                                  : JSON.parse(
                                    sessionStorage.getItem("training_providers")
                                  ).find((item) => String(item.tp_id) === String(user.tp_id))
                                    .student_number_regex_detail
                              }
                              disableRipple
                            >
                              <Icon color="primary" style={{ fontSize: "15px" }}>
                                <RxInfoCircled style={{ marginBottom: "9px" }} />
                              </Icon>
                            </Tooltip>
                          </Form.Label>
                          <Form.Control
                            type="input"

                            onChange={(e) =>
                              handleOnChangeTPArray(e,user, "cnum", index)
                            }
                            name={`cnum-${index}`}
                            isInvalid={!!trainingErrorText[`cnum-${index}`]}
                            value={user.num}
                            placeholder="Enter your student Number"
                            // disabled={(trainingProviders.find(
                            //   (item) => String(item.tp_id) === String(user.tp_id)
                            // )?.student_number_check == true) ? false : true}
                          />
                        </Form.Group>
                        <Form.Control.Feedback type="invalid">
                          {trainingErrorText[`cnum-${index}`]}
                        </Form.Control.Feedback>

                        {/* <Divider orientation="vertical" >OR</Divider>
                            
                        
                          <Box sx={{ orientation: 'horizontal', width: '25%' }}>
                            <Form.Group as={Col} style={{ marginTop: "0.5pc" }} controlId={`formCheck-${index}`}>
                              <FormControlLabel
                                label={
                                  <Typography level="body-sm">
                                    Requested for a new student number
                                  </Typography>
                                }
                                control={
                                  <Checkbox
                                    name={`trainingChecked1-${index}`}
                                    checked={user.enrollCheck ? true : false}
                                    key={user.tp_id || index}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                }
                              />
                            </Form.Group>
                          </Box> */}
                        
                      </CardOverflow>

                      <CardOverflow variant="soft" sx={{ minWidth: '10%', display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                        <CardContent>
                          <Typography level="body-sm">Consent</Typography>
                          <Form.Group controlId="formGridState">
                            {user.consent === false ?
                              <Tooltip sx={{ width: '20%' }} variant="contained" title="Required">
                                <IconButton variant="plain" name="consentCheck" color="danger" onClick={(e) => handleOnChangeTPArray(e,user, "consent", index)} aria-label="directions-down">
                                  <ErrorOutlineIcon />
                                </IconButton>
                              </Tooltip>
                              :
                              <Tooltip sx={{ width: '20%' }} variant="contained" title="Agreed">
                                {/* <IconButton variant="plain" name="consentCheck" color="success" onClick={(e) => handleOnChangeTPArray(e, user, "consent", index)} aria-label="directions-down"> */}
                                <IconButton variant="plain" name="consentCheck" color="success"  aria-label="directions-down">

                                  <TaskAltIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </Form.Group>
                        </CardContent>
                      </CardOverflow>

                      <CardOverflow variant="soft" sx={{ width: '10%', display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                        <CardContent style={{ alignContent: "center" }}>
                          <Tooltip variant="contained" title="Remove">
                            <IconButton variant="plain" color="danger" onClick={(e) => handleAddNewField(e, user, "remove", index)} aria-label="directions-down">
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Divider orientation="horizontal" />

                          {(index == newUserTrainingData.length - 1 &&
                            !showForm &&
                            newUserTrainingData.length < trainingProviders.length) &&

                            <Tooltip variant="contained" title="Add ">
                              <IconButton variant="plain" color="primary" onClick={(e) => handleAddNewField(e, user, "add Another")} aria-label="directions-down">
                                <ControlPointIcon />
                              </IconButton>
                            </Tooltip>
                          }
                        </CardContent>
                      </CardOverflow>
                    </Card>
                  </Col>
                </Row>
              </>
              )
            })}

            {/* {newUserTrainingData?.map((user, index) => {
              return (
                <p key={user.tp_id}>
                  <Row>
                    <Col>
                      <Form.Group as={Col} controlId={`trainingPV-${user.tp_id}`}>
                        <Form.Label>Training Provider</Form.Label>
                        <Form.Select
                          name="trainingProvider"
                          value={
                            trainingProviders?.find(
                              (item) => String(item.tp_id) === String(user.tp_id)
                            ).tp_id
                          }
                          isInvalid={!!trainingErrorText[`cnum-${index}`]}
                          onChange={(e) =>
                            handleOnChangeTPArray(e, "providerName", index)
                          }
                        >
                          <option value="-1">
                            --Choose Training Provider--
                          </option>
                          <option
                            value={
                              trainingProviders?.find(
                                (item) => String(item.tp_id) === String(user.tp_id)
                              ).tp_id
                            }
                          >
                            {
                              trainingProviders?.find(
                                (item) => String(item.tp_id) === String(user.tp_id)
                              ).provider_name
                            }
                          </option>
                          {trainingProviders?.map((e, key) => {
                            return newUserTrainingData.find(
                              (item) => item.tp_id == e.tp_id
                            ) == undefined ? (
                              <option value={e.tp_id} key={key}>
                                {e.provider_name}
                              </option>
                            ) : (
                              ""
                            );
                          })}

                          { }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group as={Col} controlId={`formNumC-${index}`}>
                        <Form.Label>
                          Student Number&nbsp;
                          <Tooltip
                            variant="contained"
                            title={
                              !sessionStorage.getItem("training_providers")
                                ? ""
                                : JSON.parse(
                                  sessionStorage.getItem("training_providers")
                                ).find((item) => String(item.tp_id) === String(user.tp_id))
                                  .student_number_regex_detail
                            }
                            disableRipple
                          >
                            <Icon color="primary" style={{ fontSize: "15px" }}>
                              <RxInfoCircled style={{ marginBottom: "9px" }} />
                            </Icon>
                          </Tooltip>
                        </Form.Label>
                        <Form.Control
                          type="input"

                          onChange={(e) =>
                            handleOnChangeTPArray(e, "cnum", index)
                          }
                          name={`cnum-${index}`}
                          isInvalid={!!trainingErrorText[`cnum-${index}`]}
                          defaultValue={user.num}
                          placeholder="Enter your student Number"
                          disabled={(trainingProviders.find(
                            (item) => String(item.tp_id) === String(user.tp_id)
                          )?.student_number_check == true) ? false : true}
                        />
                      </Form.Group>
                      <Form.Control.Feedback type="invalid">
                        {trainingErrorText[`cnum-${index}`]}
                      </Form.Control.Feedback>
                    </Col>
                    {user.consent != true ?
                      <Col xs={1} style={{ marginTop: "2.5%" }}>
                        <Form.Group controlId="formGridState">
                          <FormControlLabel
                            label={
                              <Typography variant="subtitle1">
                                <Tooltip
                                  variant="contained"
                                  title="Consent form"
                                  disableRipple
                                >
                                  <Icon color="primary" style={{ fontSize: "15px" }}>
                                    <RxInfoCircled style={{ marginBottom: "9px" }} />
                                  </Icon>
                                </Tooltip>
                              </Typography>
                            }
                            control={
                              <Checkbox
                                name="consentCheck"
                                checked={user.consent == true ? true : false}
                                onChange={(e) => handleOnChangeTPArray(e, "consent", index)}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                          />
                        </Form.Group>
                      </Col> : ""}
                    {user.consent == false ?
                      <Col xs={3}>
                        <DeleteIcon

                          style={{
                            cursor: "pointer",
                            margin: "8%",
                            color: "red",
                          }}
                          onClick={(e) => handleAddNewField(e, user, "remove", index)}
                        />

                      </Col> :
                      <Col>
                        <DeleteIcon

                          style={{
                            cursor: "pointer",
                            margin: "8%",
                            color: "red",
                          }}
                          onClick={(e) => handleAddNewField(e, user, "remove", index)}
                        />

                      </Col>}
                  </Row>
                  {index == newUserTrainingData.length - 1 &&
                    !showForm &&
                    newUserTrainingData.length < trainingProviders.length ? (
                    <Button
                      variant="primary"
                      style={{ margin: "2%" }}
                      onClick={(e) => handleAddNewField(e, user, "add Another")}
                    >
                      +
                    </Button>
                  ) : (
                    <div></div>
                  )}

                </p>
              );
            })} */}


            {(showForm) &&
              <>
                <Row>
                  <Col xs={12} md={12}>
                    <br></br>
                    <Card
                      size="sm"
                      variant="plain"
                      orientation="horizontal"
                      sx={{ textAlign: 'center', overflow: 'auto', gap: 1, maxWidth: '100%', minWidth: '100%' }}>
                      <CardOverflow variant="solid" color="primary" sx={{ minWidth: '25%', maxWidth: "25%", flex: '0 0 200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', px: 'var(--Card-padding)' }}>
                        <Form.Group controlId="formGridState">
                          <Form.Label>Training Provider</Form.Label>
                          <Form.Select
                          name="trainingProvider"
                          value={studentData.providerName || "-1"} // Ensure it resets to default if no value is selected
                          onChange={(e) => handleOnChangeTP(e, "providerName")}
                        >
                          <option value="-1">--Choose Training Provider--</option>
                          {trainingProviders.map((e, key) => {
                            return newUserTrainingData.find((item) => item.tp_id == e.tp_id) === undefined ? (
                              <option value={e.tp_id} key={key}>
                                {e.provider_name}
                              </option>
                            ) : (
                              ""
                            );
                          })}
                        </Form.Select>

                        </Form.Group>
                      </CardOverflow>

                      <CardOverflow variant="soft" sx={{
                        minWidth: "60%", display: 'flex', flexDirection: 'row', justifyContent: 'space-around',
                        borderTop: '1px solid', borderBottom: '1px solid', borderColor: 'divider'
                      }}>
                        <Form.Group controlId="formCNum">
                          <Form.Label>
                            Student Number&nbsp;
                            <Tooltip
                              variant="contained"
                              title={
                                !sessionStorage.getItem("training_providers") ||
                                  !studentData.providerName
                                  ? ""
                                  : JSON.parse(
                                    sessionStorage.getItem("training_providers")
                                  ).find(
                                    (item) =>
                                      item.tp_id ===
                                      parseInt(studentData.providerName)
                                  )?.student_number_regex || "" // Extract provider_name or provide a fallback
                              }
                              disableRipple
                            >
                              <Icon color="primary" style={{ fontSize: "15px" }}>
                                <RxInfoCircled style={{ marginBottom: "9px" }} />
                              </Icon>
                            </Tooltip>
                          </Form.Label>
                          <Form.Control
                            type="input"
                            name="cnum"
                            onChange={(e) => handleOnChangeTP(e, "studentNo")}
                            isInvalid={!!errorText["cnum"]}
                            placeholder="Enter your student Number"
                          />
                          <Typography level="body-xs" style={{ marginTop: "3px" }} color="danger">{errorText.cnum}</Typography>
                          </Form.Group>

                          {/* <Divider orientation="vertical" >OR</Divider> */}

                          {(trainingProviders.find(
                            (item) => String(item.tp_id) === studentData.providerName
                          )?.student_number_check == true) ? (
                            <Box sx={{ orientation: 'horizontal', width: '25%', minWidth: '25%' }}>
                              <Typography level="body-sm">
                                <Checkbox
                                  name="trainingChecked2"
                                  checked={studentData.enrollCheck}
                                  onChange={(e) => handleOnChangeTP(e, "enrollCheck")}
                                  disabled={(trainingProviders.find(
                                    (item) => String(item.tp_id) === String(studentData.providerName)
                                  )?.student_number_check == true) ? false : true}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                                Request a new student number.
                              </Typography>

                            </Box>
                          ) : <Box sx={{ orientation: 'horizontal', width: '25%', minWidth: '25%' }}>
                          </Box>}
                      </CardOverflow>

                      <CardOverflow variant="soft" sx={{ minWidth: '15%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                          <CardContent>
                            <Typography level="body-sm">Consent</Typography>
                            <FormGroup style={{ alignContent: "center" }}>
                              {(!studentData.consent) ?
                                <Tooltip sx={{ width: '20%' }} variant="contained" title="Required">
                                  <IconButton variant="outlined" name="consentCheck" color="danger" onClick={(e) => handleOnChangeTP(e, "consentCheck")} aria-label="directions-down">
                                    <ErrorOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                                :
                                <Tooltip sx={{ width: '20%' }} variant="contained" title="Agreed">
                                  {/* <IconButton variant="plain" name="consentCheck" color="success" onClick={(e) => handleOnChangeTP(e, "consentCheck")} aria-label="directions-down"> */}
                                  <IconButton variant="plain" name="consentCheck" color="success"  aria-label="directions-down">

                                    <TaskAltIcon />
                                  </IconButton>
                                </Tooltip>
                              }
                                <Divider orientation="horizontal" />
                               <Tooltip variant="contained" title="Remove">
                            <IconButton variant="plain" color="danger" onClick={(e) => handleOnChangeTP(e, "remove")} aria-label="directions-down">
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                            </FormGroup>
                          </CardContent>
                        </CardOverflow>


                    </Card>
                  </Col>
                </Row>
              </>
            }
            {/* {showForm ? (
              <Row>
                <Col xs={4}>
                  <Form.Group as={Col} controlId="formGridState">
                    <Form.Label>Training Provider</Form.Label>
                    <Form.Select
                      name="trainingProvider"
                      onChange={(e) => handleOnChangeTP(e, "providerName")}
                    >
                      <option value="-1">--Choose Training Provider--</option>
                      {trainingProviders?.map((e, kkkey) => {
                        return newUserTrainingData.find(
                          (item) => item.tp_id == e.tp_id
                        ) == undefined ? (
                          <option value={e.tp_id} key={kkkey}>
                            {e.provider_name}
                          </option>
                        ) : (
                          ""
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group as={Col} controlId="formCNum">
                    <Form.Label>
                      Student Number&nbsp;
                      <Tooltip
                        variant="contained"
                        title={
                          !sessionStorage.getItem("training_providers") ||
                            !studentData.providerName
                            ? ""
                            : JSON.parse(
                              sessionStorage.getItem("training_providers")
                            ).find(
                              (item) =>
                                item.tp_id ===
                                parseInt(studentData.providerName)
                            )?.student_number_regex || "" // Extract provider_name or provide a fallback
                        }
                        disableRipple
                      >
                        <Icon color="primary" style={{ fontSize: "15px" }}>
                          <RxInfoCircled style={{ marginBottom: "9px" }} />
                        </Icon>
                      </Tooltip>
                    </Form.Label>
                    <Form.Control
                      type="input"
                      name="cnum"
                      disabled={
                        studentData.providerName == undefined ||
                          studentData.providerName == "-1" ? true : false || (trainingProviders.find(
                            (item) => String(item.tp_id) === String(studentData.providerName)
                          )?.student_number_check == true) ? false : true
                      }
                      onChange={(e) => handleOnChangeTP(e, "studentNo")}
                      isInvalid={!!errorText["cnum"]}
                      placeholder="Enter your student Number"
                    />
                  </Form.Group>
                  <div style={{ color: "#dc3545" }}>{errorText.cnum}</div>
                </Col>
                {studentData.providerName ?
                  <Col xs={1} style={{ marginTop: "2.5%" }}>
                    <Form.Group controlId="formGridState">
                      <FormControlLabel
                        label={
                          <Typography variant="subtitle1">
                            <Tooltip
                              variant="contained"
                              title="Consent form"
                              disableRipple
                            >
                              <Icon color="primary" style={{ fontSize: "15px" }}>
                                <RxInfoCircled style={{ marginBottom: "9px" }} />
                              </Icon>
                            </Tooltip>
                          </Typography>
                        }
                        control={
                          <Checkbox
                            name="consentCheck"
                            checked={studentData.consent}
                            onChange={(e) => handleOnChangeTP(e, "consentCheck")}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                      />
                    </Form.Group>
                  </Col> : ""}
               
              </Row>
            ) : (
              <div></div>
            )} */}
            {/* <Alert
              show={showValidateCnumError}
              variant="danger"
              className="w-100 mt-3 ml-3 "
              onClose={() => setValidateCnumError(false)}
              dismissible
            >
              Invalid student number format
            </Alert> */}
<Row>
            {(showValidateCnumError) &&
                <Alert
                  variant="outlined"
                  color="danger"
                  style={{marginTop:"2pc"}}
                  startDecorator={<WarningIcon />}
                  endDecorator={
                    <IconButton variant="plain" size="sm" color="danger">
                      <CloseIcon onClick={() => setValidateCnumError(false)} />
                    </IconButton>
                  }
                >
                  Invalid student number format
                </Alert>
              }

            {/* <Alert
              show={showValidRegex}
              variant="success"
              className="w-100 mt-3 ml-3 "
              onClose={() => setShowValidRegex(false)}
              dismissible
            >
              Regex Validated
            </Alert> */}

            {(showValidRegex) &&
                <Alert
                  variant="outlined"
                  color="success"
                  style={{marginTop:"2pc"}}
                  startDecorator={<WarningIcon />}
                  endDecorator={
                    <IconButton variant="plain" size="sm" color="success">
                      <CloseIcon onClick={() => setShowValidRegex(false)} />
                    </IconButton>
                  }
                >
                  Regex Validated
                </Alert>
              }

            {/* <Alert
              show={showIncompleteFieldsError}
              className="w-100 mt-3 ml-3 "
              onClose={() => setShowIncompleteFieldsError(false)}
              dismissible
            >
              {" "}
              Please fill all fields{" "}
            </Alert> */}

            {(showIncompleteFieldsError) &&
                <Alert
                  variant="outlined"
                  color="danger"
                  style={{marginTop:"2pc"}}
                  startDecorator={<WarningIcon />}
                  endDecorator={
                    <IconButton variant="plain" size="sm" color="danger">
                      <CloseIcon onClick={() => setShowIncompleteFieldsError(false)} />
                    </IconButton>
                  }
                >
                  Please fill all fields
                </Alert>
              }



            {/* {showCnumError ? (
              <p style={{ color: "red" }}>{showCnumError}</p>
            ) : (
              ""
            )} */}

            {(showCnumError) &&
                <Alert color="danger" variant="soft"
                style={{marginTop:"2pc"}}
                startDecorator={<WarningIcon />}
                  endDecorator={
                    <IconButton variant="plain" size="sm" color="danger">
                      <CloseIcon onClick={() => setShowCnumError("")} />
                    </IconButton>
                  }
                  className='text-left'
                >{showCnumError}
                </Alert>}

            {/* {validationError.number ? (
              <div
                className="text-danger"
                style={{
                  marginLeft: "2.5pc",
                  fontSize: "0.8rem",
                  marginTop: "2px",
                }}
              >
                {" "}
                <p> {errorText["cnum"]} </p>
              </div>
            ) : (
              ""
            )} */}

          {(validationError.number && errorText["cnum"] !== "") && <Alert color="danger" style={{marginTop:"2pc"}} className='text-left'>{errorText["cnum"]}</Alert>}
          </Row>
            
            {/* <br></br>
                        <Row>
                        <Form.Group as={Col} controlId="formEnroll">
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch"
                                    name="enrollCheck"
                                    checked={newUser.enrollCheck}
                                    onChange={handleOnChange}
                                    label="Allow to create a new Lambton account with mentioned details."
                                />
                            </Form.Group>
                        </Row> */}
            <br></br>
           
            <Button
              as={Col}
              variant="primary"
              onClick={handleOnSubmit}
              disabled={Object.values(validationError).includes(true)}
            >
              Update
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              as={Col}
              variant="primary"
              onClick={() => setshowPassword(true)}
            >
              Change password
            </Button>
            <Form.Control.Feedback type="invalid">
              {errorText["cnum"]}
            </Form.Control.Feedback>
            <Row>
              <br></br>
              <Col xs={0} md={4}></Col>
              <Col xs={0} md={4}></Col>
              <Col xs={12} md={4}>
                {activeStatus === true || activeStatus === "true" ? (
                  <Button
                    style={{ float: "right", borderRadius: "1.5px" }}
                    variant="danger"
                    onClick={() => setShowDeactivation(true)}
                  >
                    De-activate account
                  </Button>
                ) : (
                  <Button
                    style={{ float: "right" }}
                    variant="success"
                    onClick={() => activateAccountForUser()}
                  >
                    Activate account
                  </Button>
                )}
              </Col>
            </Row>
            <br></br>
            {/* <Alert
              show={showDangerAlert}
              variant="danger"
              className="w-100 mt-3 ml-3 "
              onClose={() => setshowDangerAlert(false)}
              dismissible
            >
              Error while updating
            </Alert> */}

            {(showDangerAlert) &&
                <Alert color="danger" variant="soft"
                style={{marginTop:"2pc"}}
                startDecorator={<WarningIcon />}
                  endDecorator={
                    <IconButton variant="plain" size="sm" color="danger">
                      <CloseIcon onClick={() => setshowDangerAlert(false)} />
                    </IconButton>
                  }
                  className='text-left'
                >Error while updating
                </Alert>}


            {/* <Alert
              show={showSuccessAlert}
              variant="success"
              className="w-100 mt-3 ml-3 "
              onClose={() => setshowSuccessAlert(false)}
              dismissible
            >
              Successfully Updated
            </Alert> */}

            {(showSuccessAlert) &&
                <Alert
                  variant="outlined"
                  color="success"
                  style={{marginTop:"2pc"}}
                  startDecorator={<WarningIcon />}
                  endDecorator={
                    <IconButton variant="plain" size="sm" color="success">
                      <CloseIcon onClick={() => setshowSuccessAlert(false)} />
                    </IconButton>
                  }
                >
                   Successfully Updated
                </Alert>
              }
          </Form>
        </Row>
        <br></br>
        <br></br>
      </Container>
      {/* <Modal show={showUserMsg} centered style={{"border-radius":"0px","width":"20pc","top":"0%","left":"40%"}} onHide={() => setShowUserMsg(false)} closeButton>
                <Modal.Body className='text-center'>
                <h1><SlInfo style={{"font-size":"5pc","color":"#0f6fc5"}}/></h1> <br></br>{userMsg}
                </Modal.Body>
            </Modal> */}
      <ErrorPopUp
        show={showErrorMsg}
        data={errorMsg}
        onHide={() => {
          setShowErrorMsg(false);
        }}
      />
      <SpinnerBox
        show={showSpinner}
        onHide={() => {
          setShowSpinner(false);
        }}
      />
      <ChangePassword
        show={showPassword}
        data={newUser.userName}
        handleUpdateCallback={handleUpdateCallback}
        onHide={() => {
          setshowPassword(false);
        }}
      />
      <DeactivateAccount
        show={showDeactivation}
        data={newUser.userName}
        handleUpdateCallback={handleDeactivationCallback}
        onHide={() => {
          setShowDeactivation(false);
        }}
      />
      <SuccessPopUp
        show={showSuccess}
        data={successMsg}
        onHide={() => {
          setShowSuccess(false);
        }}
      />
      <UserConsentDialog
        show={consentDialog}
        handleUpdateCallback={handleConsentCallback}
        onHide={() => setConsentDialog(false)}
      />
    </div>
  );
}

export default UserProfile;