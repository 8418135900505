import './organisation.css';
import React, { useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import { BASE_API_URL } from '../../config/Url-config';
import StaffRoleCourseChecklistComponent from './StaffRoleCourseChecklistComponent';
import SuccessPopUp from '../Utility/SuccessPopUp';
import { MdOutlineMailOutline } from "react-icons/md";
import * as apiUtility from "../../config/APIUtils";
import ErrorPopUp from '../Utility/ErrorPopUp';
import { Col, Row } from 'react-bootstrap';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Divider, Grid, IconButton, InputBase, LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Tooltip, Typography } from '@mui/material';
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { RiFileExcel2Line } from "react-icons/ri";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
    '.MuiDataGrid-columnHeadersInner, .MuiDataGrid-footerContainer, .css-rtrcn9-MuiTablePagination-root': {
        backgroundColor: '#007bff', // Header and cell background color\
        fontSize: '1pc',
        color: "white"
    }
    // ,
    // '.MuiDataGrid-cell--withRenderer, .MuiDataGrid-cell MuiDataGrid-cell--textLeft, .MuiDataGrid-withBorderColor': {
    //     display: "block",
    //     textAlign:"center",
    //     width: "100%",
    //     }

}));
export default function StaffUITableSponsor(props) {

    const [showCoverageWarning, setCoverageWarningShow] = useState(false);
    const handleCloseCoverageWarning = () => setCoverageWarningShow(false);
    const handleshowCoverageWarning = () => setCoverageWarningShow(true);
    const [staffList, setStaffList] = useState([]);
    const [excelStaffList, setExcelStaffList] = useState([]);

    
    const [coverageObj, setCoverageObj] = useState({});

    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [dataForSuccessPopup, setDataForSuccessPopup] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [dataForErrorPopup, setDataForErrorPopup] = useState('');
    const [hideRoles, setHideRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 25,
    });
    const [sortModel, setSortModel] = React.useState([{
        field: "",
        sort: "asc",
    }]);
    //const [queryOptions, setQueryOptions] = React.useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [searchText, setSearchText] = useState("");
    //const params = useParams().id;
   
    useEffect(() => {
        setSearchText("");
        fetchDataFromServer();
    }, [paginationModel.page, paginationModel.pageSize, props.data.homeID, sortModel])

    function fetchStaffTrainingData(){
        setCoverageObj({})
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getStaffTrainingSponsorshipData/"+props.data.homeID;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            console.log(res)
            if (res.data != 'Something went wrong!' && res.data != 'No Staff Found!'){
               // alert('done');
                console.log(res);
                setStaffList(res.data)
            }
            else{
                setStaffList([]);
            }
                
        }).catch((error) => {
            console.error(error);
        });
    }

    function handleNewFields(){
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/addNewFields";
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            if (res != 'Something went wrong!' || res != 'No Role Found!'){
                alert('done');
            }
                
        }).catch((error) => {
            console.error(error);
        });
    }

    function change_CoverageStatus(event,role_id,user_id,data){
        console.log(event.target.value,role_id,user_id,data);
        if(event.target.value=='Active' && data.coverage_status!='Pending'){
            if(data.emp_status==='Hold'){
                setCoverageObj({'coverageStatus':'Pending','coverage_activation_date':new Date(),'role_id':role_id,'user_id':user_id,'home_id':props.data.homeID});
            }
            else if(data.emp_status==='Active'){
                setCoverageObj({'coverageStatus':'Active','coverage_activation_date':new Date(),'role_id':role_id,'user_id':user_id,'home_id':props.data.homeID});
            }
            handleshowCoverageWarning();

        }
        else if(event.target.value=='Inactive'){
            setCoverageObj({'coverageStatus':event.target.value,'coverage_activation_date':'N/A','role_id':role_id,'user_id':user_id,'home_id':props.data.homeID});
            handleshowCoverageWarning();

        }
    }

    const clearFormatting = () => {
        setLoading(true);
        setSearchText("");
        //fetchData(paginationModel.page, paginationModel.pageSize, "", "", "")
        fetchStaffTrainingData()
        setLoading(false);
    }

    const handleSearch = (e) => {
        e.preventDefault();
        setLoading(true);
        //console.log("event", e.target.value)
        // if(searchText !== ""){
        // const filteredData = staffListAll.filter(value => {
        //     const searchStr = e.target.value.toLowerCase();
        //     const nameMatches = value.fullName.toLowerCase().includes(searchStr);
        //     return nameMatches;
        // });
       
       // fetchData(paginationModel.page, paginationModel.pageSize, searchText, "", "")
       fetchData(paginationModel.page, paginationModel.pageSize, searchText, "", "")
        setPaginationModel({...paginationModel,  page: 0, pageSize: 25}) 
        setLoading(false);
        //setStaffList(filteredData)
        //console.log("RowCount Search :", filteredData.length);
        // setTotalRows(filteredData.length);
        // setData(filteredData);
        // }
    }

    const captureKeyDown = (e) => {
        if (e.keyCode == 13) {
            e.preventDefault();
            handleSearch(e);
        }
    }

   

    const fetchDataFromServer = async () => {
        setLoading(true);
        await fetchData(paginationModel.page, paginationModel.pageSize, searchText, sortModel, "");
        setLoading(false);
    };
    const fetchData = async (page, pageSize, searchBy, sortModel, searchColumn) => {
        setLoading(true);
        // Perform server-side logic here (filtering, sorting, etc.)
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.data = {
            "page": page,
            "pageSize": pageSize,
            "homeId": props.data.homeID,
            "search": searchBy,
            "sortBy": sortModel,
            "searchColumn": searchColumn
        }
        //console.log("Sending Data : ",apiRequest.data);
        apiRequest.endPoint = "orgnization/getStaffTrainingSponsorshipData/"+props.data.homeID;
        await apiUtility.sendAPIDataPOST(apiRequest).then(res => {
            //console.log(res)
            if(res.data!="No Staff Found!"){
                
                var result = res.data.result;

                //console.log("Response Data : ",res);
                setStaffList(result)
                setTotalRows(res.data.totalCount);
                setLoading(false);
            }
           
           
        }).catch(e => {
            console.log("Error: ", e);
            //setDataForErrorPopup(e.response.data);
            //setShowErrorPopup(true);
        })
    };
    const columns = [
        // Define your columns here
        {
            field: '_id',
            headerAlign: 'center',
            flex: 0.5,
            filterable: true,
            renderHeader: () => (
                <Typography sx={{ alignItems: "center" }} variant="subtitle1">
                    {'S.No'}
                </Typography>
            ),
            renderCell: (params) => (
                <Typography variant="subtitle1" color="primary" align="center" >{params.row._id}&nbsp;</Typography>
            )
        },
        {
            field: 'user_id',
            headerAlign: 'center',
            flex: 1,
            filterable: true,
            sortable: true,
            renderHeader: () => (
                <Typography sx={{ alignItems: "center" }} variant="subtitle1">
                    {'  UserID'}
                </Typography>
            ),
            renderCell: (params) => (
                <Typography variant="subtitle1" color="primary" align="center" >{params.row.user_id}&nbsp;</Typography>

            )
        },
        {
            field: 'firstName',
            headerAlign: 'left',
            filterable: true,
            flex: 1.5,
            sortable: true,
            renderHeader: () => (
                <Typography variant="subtitle1">
                    {'First Name'}
                </Typography>
            ),
            renderCell: (params) => (
                <Typography variant="subtitle1" color="primary" align="center" >{params.row.firstName}&nbsp;</Typography>
            ),
        },
        {
            field: 'lastName',
            headerAlign: 'left',
            filterable: true,
            flex: 1.5,
            sortable: true,
            renderHeader: () => (
                <Typography variant="subtitle1">
                    {'Last Name'}
                </Typography>
            ),
            renderCell: (params) => (
                <Typography variant="subtitle1" color="primary" align="center" >{params.row.lastName}&nbsp;</Typography>
            ),
        },
    
        {
            field: 'role_name',
            renderHeader: () => (
                <Typography variant="subtitle1">
                    {'Role'}
                </Typography>
            ),
            headerAlign: 'center',
            flex: 2,
            filterable: false,
            sortable: true,
            renderCell: (params) => (

                (<Typography variant="subtitle1" color="primary" align="center" >{params.row.role_name}&nbsp;</Typography>)
            )

        },
        {
            field: 'coverage_activation_date',
            renderHeader: () => (
                <Typography variant="subtitle1">
                    {'Coverage Activation'}
                </Typography>
            ),
            headerAlign: 'center',
            flex: 2,
            filterable: false,
            sortable: true,
            renderCell: (params) => (

                (<Typography variant="subtitle1" color="primary" align="center" >{(params.row.coverage_status === 'Pending' || params.row.coverage_status === 'Inactive') ?'N/A':new Date(params.row.coverage_activation_date).toLocaleDateString()}&nbsp;</Typography>)
            )

        },
        {
            field: 'coverage_status',
            renderHeader: () => (
                <Typography variant="subtitle1">
                    {'Coverage Status'}
                </Typography>
            ),
            headerAlign: 'center',
            flex: 3,
            filterable: false,
            sortable: true,
            renderCell: (params) => (
                
                <ButtonGroup>
                <ToggleButton
                    id={`radio1-${params.row._id}`}
                    type="radio"
                    variant={params.row.coverage_status === 'Inactive' ? 'danger' : 'outline-danger'}
                    name={`radio-${params.row._id}`}
                    value="Inactive"
                    size="sm"
                    defaultChecked={params.row.coverage_status === 'Inactive'}
                    onChange={(e) => change_CoverageStatus(e, params.row.role_id, params.row.user_id,params.row)}
                >
                    Inactive
                </ToggleButton>
                <ToggleButton
                    id={`radio2-${params.row._id}`}
                    type="radio"
                    variant={params.row.coverage_status === 'Active' ? 'success' : 'outline-success'}
                    name={`radio-${params.row._id}`}
                    value="Active"
                    size="sm"
                    defaultChecked={params.row.coverage_status === 'Active'}
                    onChange={(e) => change_CoverageStatus(e, params.row.role_id, params.row.user_id,params.row)}
                >
                    Active
                </ToggleButton>
                <ToggleButton
                    id={`radio3-${params.row._id}`}
                    type="radio"
                    variant={params.row.coverage_status === 'Pending' ? 'warning' : 'outline-warning'}
                    name={`radio-${params.row._id}`}
                    value="Pending"
                    size="sm"
                    defaultChecked={params.row.coverage_status === 'Pending'}
                    onChange={(e) => change_CoverageStatus(e, params.row.role_id, params.row.user_id,params.row)}
                >
                    Pending
                </ToggleButton>
                </ButtonGroup>)


        },
    ];


    const onFilterChange = async (filterModel) => {
        const re = /^[0-9\b]+$/;
        //setQueryOptions({ filterModel: { ...filterModel } });
        //console.log("FILTER Model : ", filterModel, filterModel.items.length);
        if (filterModel.items !== undefined && filterModel.items.length > 0 && filterModel.items[0].value !== undefined) {
            for(let item of filterModel.items){
                //console.log("Item Model : ", item);
                if(item.field === "_id" && !re.test(item.value) && item.value !== ""){
                    setDataForErrorPopup("Invalid search value!");
                    setShowErrorPopup(true);
                    continue;
                }
                if(item.field === "_id" && item.value !== "" && item.value.length < 4){
                    continue;
                }
                if(item.value === ""){
                    await fetchData(paginationModel.page, paginationModel.pageSize, "", sortModel, "");    
                    //fetchStaffTrainingData()
                }
                else{
                    await fetchData(paginationModel.page, paginationModel.pageSize, item.value, sortModel, item.field);
                  //fetchStaffTrainingData()
                }
            };
          }
    };

   
    function saveCoverageStatus(){
        console.log(coverageObj);

        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/updateCoverageStatus";
        apiRequest.data = coverageObj;
        apiUtility.sendAPIDataPOST(apiRequest).then(res => {
            if (res != 'Something went wrong!' || res != 'No Role Found!'){
               // alert('done');
                console.log(res);
                //fetchStaffTrainingData();
                fetchDataFromServer()
                handleCloseCoverageWarning();
            }
                
        }).catch((error) => {
            console.error(error);
        });
       
    }


    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };
        const wbout = XLSX.write(workbook, wopts);

        // The application/octet-stream MIME type is used for unknown binary files.
        // It preserves the file contents, but requires the receiver to determine file type,
        // for example, from the filename extension.
        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });
        return blob;
    };
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; i++) {
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const exportInExcel = async () => {
        try {
            // var excelStaffList = [];
            var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = "orgnization/getStaffTrainingSponsorshipDataForExcel/"+props.data.homeID;
        apiUtility.fetchAsyncAPIDataGET(apiRequest).then(res => {
            console.log(res.data.result)
            if (res.data != 'Something went wrong!' && res.data != 'No Staff Found!'){
               // alert('done');
                console.log(res);
                setExcelStaffList(res.data.result);
                // excelStaffList = (res.data.result)
            }
            else{
                setExcelStaffList([]);
            }
        }
        )
            var rowsCount = 6;
            var homeName = '';
            if(props){
                homeName = (props.data.homesList.find(item=>item.home_id===props.data.homeID)).name+'('+props.data.homeID+')';
            }
            
            const title = [{ A: 'Ontario Long Term Care Orientation Co-operative Webportal - Staff Report for ' + String(homeName) }];
            let reportView = [{ A: 'Name', B: 'User ID', C: 'Role', D: 'Date' }];
            reportView.push({
                A: sessionStorage.getItem('fullName'),
                B: sessionStorage.getItem('userId'),
                C: (sessionStorage.getItem('userType') !== null)?sessionStorage.getItem('userType').toUpperCase():"User",
                D: new Date().toLocaleString()
            });
            reportView.push("");
            reportView.push("");
            let reportHeaders = { A: 'User ID', B: 'First Name', C: 'Last Name', D: 'Role Name', E: 'Coverage Activation', F: 'Coverage Status' };
            reportView.push(reportHeaders);

            excelStaffList.forEach(element => {
                
                    rowsCount += 1;
                    var tempObj = {
                        A: element.user_id,
                        B: element.firstName,
                        C: element.lastName,
                        D: element.role_name,
                        E:(element.coverage_status === 'Pending' || element.coverage_status === 'Inactive') ?'N/A':new Date(element.coverage_activation_date).toLocaleDateString(),
                        F: element.coverage_status,
                    }
                    reportView.push(tempObj);
            });
            console.log(excelStaffList)

            const finalData = [...title, ...reportView];
            const worksheet = XLSX.utils.json_to_sheet(finalData, {
                skipHeader: true,
            });
            console.log(finalData)
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Staff Details");
            const workbookblob = workbook2blob(workbook);
            var headerIndexes = [];
            finalData.forEach((data, index) =>
                data["A"] === "User ID" ? headerIndexes.push(index) : null
            );
            const dataInfo = {
                titleCell: "A2",
                titleRange: "A1:G1",
                tbodyRange: `A7:F${finalData.length}`,
                uHeadRange: `A2:D2`,
                uBodyRange: `A3:D3`,
                theadRange:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[0] + 1}:F${headerIndexes[0] + 1}`
                        : null,
                theadRange1:
                    headerIndexes?.length >= 2
                        ? `A${headerIndexes[1] + 1}:F${headerIndexes[1] + 1}`
                        : null,
                tFirstColumnRange:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[0] + 1}:A${rowsCount + headerIndexes[0] + 1}`
                        : null,
                tLastColumnRange:
                    headerIndexes?.length >= 1
                        ? `K${headerIndexes[0] + 1}:G${rowsCount + headerIndexes[0] + 1}`
                        : null,

                tFirstColumnRange1:
                    headerIndexes?.length >= 1
                        ? `A${headerIndexes[1] + 1}:A${rowsCount + headerIndexes[1] + 1}`
                        : null,
                tLastColumnRange1:
                    headerIndexes?.length >= 1
                        ? `H${headerIndexes[0] + 1}:F${rowsCount + headerIndexes[1] + 1}`
                        : null,
            };
            XlsxPopulate.fromDataAsync(workbookblob).then((workbook) => {
                workbook.sheets().forEach((sheet) => {
                    sheet.column("A").width(15);
                    sheet.column("B").width(30);
                    sheet.column("C").width(30);
                    sheet.column("D").width(30);
                    sheet.column("E").width(40);
                    sheet.column("F").width(20);
                    sheet.range(dataInfo.titleRange).merged(true).style({
                        bold: true,
                        fontFamily: "Arial",
                        horizontalAlignment: "center",
                        verticalAlignment: "center",
                    });
                    sheet.range(dataInfo.uHeadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    sheet.range(dataInfo.uBodyRange).style({
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    if (dataInfo.tbodyRange) {
                        sheet.range(dataInfo.tbodyRange).style({
                            horizontalAlignment: "left",
                        });
                    }
                    sheet.range(dataInfo.theadRange).style({
                        fill: "A6A6A6",
                        bold: true,
                        horizontalAlignment: "center",
                    });
                    if (dataInfo.tFirstColumnRange) {
                        sheet.range(dataInfo.tFirstColumnRange).style({
                            bold: true,
                        });
                    }

                });

                return workbook.outputAsync().then((workbookBlob) => {
                    const downloadAnchorNode = document.createElement("a");
                    var dateVar = new Date();
                    var fileName = "LCPT_StaffDetails-" + dateVar.getDate() + dateVar.getMonth() + dateVar.getFullYear() + "_" + dateVar.getHours() + dateVar.getMinutes() + ".xlsx";
                    downloadAnchorNode.setAttribute("href", URL.createObjectURL(workbookBlob));
                    downloadAnchorNode.setAttribute("download", fileName);
                    downloadAnchorNode.click();
                    downloadAnchorNode.remove();
                    setDataForSuccessPopup("Downloaded Successfully");
                    setShowSuccessPopup(true);
                });
            });
        } catch (error) {
            setDataForErrorPopup("Something went wrong while processing excel!");
            setShowErrorPopup(true);
        }
    }

     return (
        <div className='org-container'>


            <div md={6} className="mb-2">
                
               
            </div>
            <Row>
                <Col>
                    <h5>Staff Members for Home : </h5>
                </Col>
                <Col>
                <Col xs={12} md={5}>
                    <Paper component="form" variant="outlined"
                        sx={{ float: "right", display: 'flex', marginBottom: "4px", alignItems: 'center', width: 450 }}
                    >

                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search by Firstname or Lastname"
                            inputProps={{ 'aria-label': 'search by last or first' }}
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                            }}
                            onKeyDown={captureKeyDown}
                        />
                        <Tooltip variant="contained" title="Search" disableRipple>
                            <IconButton type="button" onClick={handleSearch} sx={{ p: '10px' }} aria-label="search">
                                <ManageSearchIcon />
                            </IconButton>
                        </Tooltip>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <Tooltip variant="contained" title="Reset Grid" disableRipple>
                            <IconButton color="primary" onClick={clearFormatting} sx={{ p: '10px' }} aria-label="directions">
                                <ClearAllIcon />
                            </IconButton>
                        </Tooltip>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        {/* <Tooltip variant="contained" title="Export to Excel" disableRipple>
                            <IconButton color="primary" onClick={exportInExcel} sx={{ p: '10px' }} aria-label="directions">
                                <RiFileExcel2Line />
                            </IconButton>
                        </Tooltip> */}

                    </Paper>
                </Col>
                </Col>
            
            </Row>

            {/* <Button style={{  marginRight: "1%", marginBottom: "1%" }} variant="warning" onClick={handleNewFields}>Add New Fields</Button> */}
            <Modal style={{ width: "50%", left: "25%", top: "20%" }} show={showCoverageWarning} onHide={handleCloseCoverageWarning}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Warning</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <h5>Are you sure you want to change the Coverage Status for this role?</h5>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseCoverageWarning}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={saveCoverageStatus}>
                                            Yes
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
            {/* <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>S.N.</th>
                        <th>User ID</th>
                        <th style={{cursor:"pointer"}}>First Name </th>
                        <th style={{cursor:"pointer"}}>Last Name </th>
                        <th>Role</th>
                        <th>Coverage Activation</th>
                        <th>Coverage Status</th>
                       
                    </tr>
                </thead>

                {staffList?.map((data, id) => {
                    return <tbody key={id}>
                        <tr >
                            <td>{id + 1}</td>
                            <td>{data.user_id}</td>
                            <td>{data.firstName}</td>
                            <td>{data.lastName}</td>
                            <td>{data.role_name}</td>
                            <td>{data.coverage_activation_date}</td>
                            <td>
                             <ButtonGroup>
                            <ToggleButton
                                id={`radio1-${id}`}
                                type="radio"
                                variant={data.coverage_status === 'Inactive' ? 'danger' : 'outline-danger'}
                                name={`radio-${id}`}
                                value="Inactive"
                                size="sm"
                                defaultChecked={data.coverage_status === 'Inactive'}
                                onChange={(e) => change_CoverageStatus(e, data.role_id, data.user_id,data)}
                            >
                                Inactive
                            </ToggleButton>
                            <ToggleButton
                                id={`radio2-${id}`}
                                type="radio"
                                variant={data.coverage_status === 'Active' ? 'success' : 'outline-success'}
                                name={`radio-${id}`}
                                value="Active"
                                size="sm"
                                defaultChecked={data.coverage_status === 'Active'}
                                onChange={(e) => change_CoverageStatus(e, data.role_id, data.user_id,data)}
                            >
                                Active
                            </ToggleButton>
                            <ToggleButton
                                id={`radio3-${id}`}
                                type="radio"
                                variant={data.coverage_status === 'Pending' ? 'warning' : 'outline-warning'}
                                name={`radio-${id}`}
                                value="Pending"
                                size="sm"
                                defaultChecked={data.coverage_status === 'Pending'}
                                onChange={(e) => change_CoverageStatus(e, data.role_id, data.user_id,data)}
                            >
                                Pending
                            </ToggleButton>
                            </ButtonGroup>
                            </td>
                            </tr>
                            </tbody>
                }
            )}



            </Table> */}


            <CustomDataGrid
                 loading={loading}
                rowCount={totalRows}
                rows={staffList}
                columns={columns}
                slots={{
                    loadingOverlay: LinearProgress
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                paginationMode="server"

                componentsProps={{ panel: { disablePortal: true } }}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}

                sortingMode="server"
                onSortModelChange={setSortModel}
                rowSelectionModel={rowSelectionModel}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                getRowId={(row) => row._id}

                sx={{ p: 0 }}
                autoHeight={true} 
                getRowHeight={() => 'auto'}

            /> 
             <Tooltip variant="contained" title="Export to Excel" disableRipple>
                            <IconButton color="primary" onClick={exportInExcel} sx={{ p: '10px' }} aria-label="directions">
                                <RiFileExcel2Line />
                            </IconButton>
                        </Tooltip>
        </div>);
}
