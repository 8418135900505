import { Image } from "react-bootstrap";
import pic from "../homepageImg.jpg";
import { BASE_URL_FRONTEND } from "../config/Url-config";
import { Alert, Box, Button, Typography } from "@mui/joy";
import WarningIcon from '@mui/icons-material/Warning';

function Homepage() {
  const currentURL = window.location.href;
  if (process.env.NODE_ENV === 'production' && currentURL.includes("herokuapp.com")) {
    alert("Please use new URL : " + BASE_URL_FRONTEND)
    //return window.location.href = BASE_URL_FRONTEND;
  }
  // let textStyle = {
  //   position: 'absolute',
  //   top: '20%',
  //   left: '70%',
  //   color: "#1d3d80"
  // };

  // let textStyleAlert = {
  //   position: 'absolute',
  //   top: '10%',
  //   left: '3%',
  //   width: "50pc"
  // };

  // let textStyleAlert2 = {
  //   position: 'absolute',
  //   top: '40%',
  //   left: '3%',
  //   width: "50pc"
  // };


  const registerPage = () => {
    return window.location.href = BASE_URL_FRONTEND + "userRegistration";
  }
  return (
    <Box sx={{ marginTop: "2.8pc", position: 'relative', width: '100%', height: { xs: '500px', md: '100%' } }}>

      <Box
        component="img"
        src={pic}
        alt="Background"
        sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />

      {/* <Box
        sx={{
          position: 'absolute',
          top: { xs: '40%', md: '10%' },
          left: { xs: '5%', md: '5%' },
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '90%', md: '50%' },
          gap: 2,
        }}
      >
        <Box
          sx={{
            color: 'white',
            borderRadius: '4px',
          }}
        >
          <Alert startDecorator={<WarningIcon />} className='text-left' color="danger" >
            <Typography level="body-sm"> Training Provider Holiday Notice: Lambton College is closed from December 25 until January 1, and will reopen on Thursday, January 2. The following College services will reduced or unavailable:<br></br>
              <Typography level="body-xs" color="danger">- Course registration requests - Reduced services - Processed December 27, 30, 31</Typography><br></br>
              <Typography level="body-xs" color="danger">- Online Education support - Closed and unavailable</Typography><br></br>
              <Typography level="body-xs" color="danger">- College IT services - Closed and unavailable</Typography><br></br>
              <Typography level="body-xs" color="danger">- D2L course access support - Closed and unavailable</Typography><br></br>
              <Typography level="body-xs" color="danger">- Portal requested new Lambton College student number creation - Unavailable - Please contact carepassporthelp@lambtoncollege.ca for alternate means of obtaining a student number</Typography>
            </Typography>
          </Alert>
        </Box>
        <Box
          sx={{
            color: 'white',
            borderRadius: '4px',
          }}
        >
          <Alert startDecorator={<WarningIcon />} color="danger" >
            <Typography level="body-sm" className="text-left"> Care Passport Portal Holiday Notice: Care Passport Portal activities will be available throughout the Holiday season. Response times at carepassporthelp@lambtoncollege.ca may be delayed by up to 24hrs, and requests relating to Training Provider issues will be impacted by College Holiday Closure.</Typography>
          </Alert>
        </Box>
      </Box> */}


      <Box
        sx={{
          position: 'absolute',
          top: { xs: '10%', md: '10%' },
          right: { xs: 'auto', md: '5%' },
          bottom: { xs: '5%', md: 'auto' },
          width: { xs: '90%', md: '30%' },
          padding: '8px',
          borderRadius: '4px',
          color: "#1d3d80"
        }}
      >
        <h1 className="display-5">Simplify your <br></br>Training Record Management <br></br><Button bsStyle="primary" size="md" onClick={registerPage}>SignUp</Button></h1>
      </Box>
    </Box>
  );


  //   <div style={{ width: 'auto' }}>
  //     <Image style={{ "max-width": "100%", "box-shadow": "0 0 8px 8px white inset", "backgroundSize": 'cover' }} responsive src={pic} />
  //     <Alert startDecorator={<WarningIcon />}  className='text-left' color="danger" style={textStyleAlert}>
  //       <Typography> Training Provider Holiday Notice: Lambton College is closed from December 25 until January 1, and will reopen on Thursday, January 2. The following College services will reduced or unavailable:<br></br>
  //       <Typography color="danger">- Course registration requests - Reduced services - Processed December 27, 30, 31</Typography><br></br>
  //       <Typography color="danger">- Online Education support - Closed and unavailable</Typography><br></br>
  //       <Typography color="danger">- College IT services - Closed and unavailable</Typography><br></br>
  //       <Typography color="danger">- D2L course access support - Closed and unavailable</Typography><br></br>
  //       <Typography color="danger">- Portal requested new Lambton College student number creation - Unavailable - Please contact carepassporthelp@lambtoncollege.ca for alternate means of obtaining a student number</Typography>
  // </Typography>
  // </Alert>
  // <br></br>
  // <Alert startDecorator={<WarningIcon />} color="danger" style={textStyleAlert2}>
  // <Typography className="text-left"> Care Passport Portal Holiday Notice: Care Passport Portal activities will be available throughout the Holiday season. Response times at carepassporthelp@lambtoncollege.ca may be delayed by up to 24hrs, and requests relating to Training Provider issues will be impacted by College Holiday Closure.</Typography>
  // </Alert>
  //     <h1 style={textStyle} className="display-5">Simplify your <br></br>Training Record Management <br></br><Button bsStyle="primary" size="md" onClick={registerPage}>SignUp</Button></h1>

  //   </div>

}






export default Homepage;































